import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InlineWidget } from "react-calendly";
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { useTranslation } from "react-i18next";

import { MdError } from "react-icons/md";
import { GrLinkNext, GrFormDown, GrFormUp, GrFormNextLink, GrWaypoint } from "react-icons/gr";
import '../../App.css';

mapboxgl.accessToken = 'pk.eyJ1Ijoic3d5Y28iLCJhIjoiY2x1OGJ5MWEyMDlrcDJrcHFha3p1NG45biJ9.AOswJYHiWz7GNWWYJL6wiw';

export default function Step3() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [shipClothesAux, setShipClothesAux] = useState(JSON.parse(localStorage.getItem("shipClothes")))
    const [deliveryOption, setDeliveryOption] = useState(0)
    const [changeLocation, setChangeLocation] = useState(false)
    const [mapLocationAux, setMapLocationAux] = useState({ zipcode: null, country_code: null })
    const [shippingCountries, setShippingCountries] = useState(null)
    const [errorPickupPoints, setErrorPickupPoint] = useState(null)

    const [mapLocation, setMapLocation] = useState([-8.607443, 41.55977])
    const [pickupPoint, setPickupPoint] = useState([])

    const mapContainer = useRef(null);
    const map = useRef(null);
    const city = useRef(null);

    useEffect(() => {
        getPickupPointsShippingCountries()
        if (shipClothesAux?.pickuppoint_id?.zipcode) {
            getPickupPoints(shipClothesAux.pickuppoint_id.zipcode, shipClothesAux.pickuppoint_id.country_code)
        }
    }, [])

    useEffect(() => {
        localStorage.setItem("shipClothes", JSON.stringify(shipClothesAux))
    }, [shipClothesAux])

    async function openMap() {
        if (shipClothesAux?.pickuppoint_id?.zipcode) {
            getPickupPoints(shipClothesAux.pickuppoint_id.zipcode, shipClothesAux.pickuppoint_id.country_code)
        } else {
            await pointsNearMe()
        }
        setDeliveryOption(1)
    }
    useEffect(() => {
        if (deliveryOption == 1) {
            if (!mapContainer.current) return;
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/mapbox/streets-v12',
                center: mapLocation,
                zoom: 12
            });
            map.current.addControl(new mapboxgl.NavigationControl())
            map.current.on('load', () => {
                map.current.loadImage(
                    'https://res.cloudinary.com/dtqdhtmii/image/upload/v1712155756/Pointer-1_p00nml.png',
                    (error, image) => {
                        if (error) throw error;

                        // Add the image to the map style.
                        map.current.addImage('dhlPoint', image);

                        const mySource = map.current.getSource('point');
                        if (mySource?.id == "point") {
                            map.current.removeLayer('points');
                            map.current.removeSource('point');
                        }

                        map.current.addSource('point', {
                            // This GeoJSON contains features that include an "icon"
                            // property. The value of the "icon" property corresponds
                            // to an image in the Mapbox Streets style's sprite.
                            'type': 'geojson',
                            'data': {
                                'type': 'FeatureCollection',
                                'features': pickupPoint
                            }
                        });

                        // Add a layer showing the places.
                        map.current.addLayer({
                            'id': 'points',
                            'type': 'symbol',
                            'source': 'point', // reference the data source
                            'layout': {
                                'icon-image': 'dhlPoint', // reference the image
                                'icon-size': 1,
                                "icon-allow-overlap": true
                            }
                        });
                    });

                map.current.on('click', 'points', (e) => {
                    map.current.loadImage('https://res.cloudinary.com/dtqdhtmii/image/upload/v1712155756/SelectedPointer-1_poiya7.png', function (error, image) {
                        // if (error) throw error;
                        map.current.addImage('selected-point', image);
                        var features = map.current.queryRenderedFeatures(e.point, { layers: ['points'] });
                        map.current.setLayoutProperty('points', 'icon-image', ['match', ['get', 'id'], features[0].properties.id, 'selected-point', 'dhlPoint'])
                    });
                    var selectedPoint = {
                        id: e.features[0].properties.id,
                        title: e.features[0].properties.title,
                        street: e.features[0].properties.street,
                        zipcode: e.features[0].properties.zipcode,
                        country_code: e.features[0].properties.country_code,
                        city: e.features[0].properties.city
                    }
                    setShipClothesAux({ ...shipClothesAux, pickuppoint_id: selectedPoint })
                });

                // Change the cursor to a pointer when the mouse is over the places layer.
                map.current.on('mouseenter', 'points', () => {
                    map.current.getCanvas().style.cursor = 'pointer';
                });

                // Change it back to a pointer when it leaves.
                map.current.on('mouseleave', 'points', () => {
                    map.current.getCanvas().style.cursor = '';
                });
            });
        }

    }, [deliveryOption, pickupPoint]);

    async function getPickupPoints(zipcode, country_code) {
        if (zipcode && country_code) {
            var myHeaders = new Headers();

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            await zipcodeValidator(zipcode)
            var params = 'zipcode=' + zipcode + '&city=' + city.current + '&countrycode=' + country_code.toLowerCase() + '&carrier=1'
            await fetch(process.env.REACT_APP_URL + '/commons/collectpoints?' + params, requestOptions)
                .then(async response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        var error = await response.text();
                        return Promise.reject({ status: response.status, message: error });
                    }
                })
                .then(response => {
                    const uniqueLocations = response.filter((obj, index) => {
                        return index === response.findIndex(o => obj.id === o.id);
                    });
                    setMapLocation({ lng: uniqueLocations[0].geoLocation.lng, lat: uniqueLocations[0].geoLocation.lat })
                    var pickupPointsNew = []
                    uniqueLocations.map(location => {
                        var locationjson = {
                            'type': 'Feature',
                            'properties': {
                                'title': location.name,
                                'street': location.street,
                                'city': location.city,
                                'zipcode': location.zipcode,
                                'country_code': location.country_code,
                                'id': location.id
                            },
                            'geometry': {
                                'type': 'Point',
                                'coordinates': [location.geoLocation.lng, location.geoLocation.lat]
                            }
                        }
                        pickupPointsNew.push(locationjson)
                    })
                    setPickupPoint(pickupPointsNew)
                })
                .catch(error => {
                    if (error.status == 401) {
                        localStorage.clear()
                        navigate("/login-account")
                    }
                });
        } else {

        }
    }
    async function pointsNearMe() {
        setChangeLocation(false)
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const pos = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    fetch('https://geocode.maps.co/reverse?api_key=6617fff29fe00805651850ogd59bea6&lat=' + pos.lat + '&lon=' + pos.lng)
                        .then(async response => {
                            if (response.ok) {
                                return response.json();
                            } else {
                                var error = await response.text();
                                return Promise.reject({ status: response.status, message: error });
                            }
                        })
                        .then(async response => {
                            await getPickupPoints(response.address.postcode, response.address.country_code)
                        })
                        .catch(error => {
                            if (error.status == 401) {
                                localStorage.clear()
                                navigate("/login-account")
                            }
                        });
                },
            );
        }
    }
    function getPickupPointsShippingCountries() {
        var myHeaders = new Headers();

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + '/cart/shippingcountries?id_brand=' + process.env.REACT_APP_ID_BRAND, requestOptions)
            .then(async response => {
                if (response.ok) {
                    return response.json();
                } else {
                    var error = await response.text();
                    return Promise.reject({ status: response.status, message: error });
                }
            })
            .then(response => {
                const shippingCountriesFiltered = response.filter((obj, index) => {
                    return index === response.findIndex(o => obj.country_from === o.country_from);
                });
                setShippingCountries(shippingCountriesFiltered)
                if (!mapLocationAux.country_code) {
                    var country = shippingCountriesFiltered.find(country => country.country_from_name == "Portugal")
                    setMapLocationAux({ ...mapLocationAux, country_code: country.country_from_code })
                }
            })
            .catch(error => {
                if (error.status == 401) {
                    localStorage.clear()
                    navigate("/login-account")
                }
            });
    }
    async function zipcodeValidator(zipcode) {
        setErrorPickupPoint(null)

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };
        await fetch('https://json.geoapi.pt/codigo_postal/' + zipcode, requestOptions)
            .then((response) => {
                return response.json()
            })
            .then(response => {
                if (response?.erro) {
                    setErrorPickupPoint(t("ship_my_clothes.error_zipcode"))
                } else {
                    setErrorPickupPoint(null)
                    city.current = response["Concelho"]
                }
            })
    }
    function goToNextStep() {
        if (deliveryOption == 0 || (deliveryOption == 1 && shipClothesAux.pickuppoint_id)) {
            navigate("/ship-my-clothes/choose-delivery/delivery/summary")
        }
    }

    return (
        <div className='w-full h-full page-footer-buttons'>
            <div className='w-full h-full flex flex-col justify-between gap-4'>
                <div className='h-full w-full flex flex-col gap-3'>
                    <h2>{t("ship_my_clothes.delivery_details")}</h2>
                    <div className='w-full flex toogle-buttons'>
                        <button onClick={() => setDeliveryOption(0)} className={'w-1/2 button ' + (deliveryOption == 0 ? 'primary-button ' : 'toogle-button-secundary')}>{t("ship_my_clothes.schedule")}</button>
                        <button onClick={() => openMap()} className={'w-1/2 button ' + (deliveryOption == 1 ? 'primary-button !ml-1' : 'toogle-button-secundary')}>{t("ship_my_clothes.pickup_point")}</button>
                    </div>
                    {deliveryOption == 0 ?
                        <div className='w-full h-full relative'>
                            <InlineWidget url="https://calendly.com/diogo-passos" />
                        </div>
                        :
                        <div className='mt-[12px] w-full h-full flex flex-col gap-3'>
                            <div className='w-full flex flex-col gap-2'>
                                <div className='w-full flex justify-between text-sm'>
                                    <button onClick={pointsNearMe} className='underline underline-offset-2 flex gap-1 items-center'><GrWaypoint />{t("ship_my_clothes.pointers_near_me")}</button>
                                    <button onClick={() => setChangeLocation(!changeLocation)} className='font-bold text-primary flex gap-1 items-center'>{t("ship_my_clothes.change_location")} {changeLocation ? <GrFormUp /> : <GrFormDown />}</button>
                                </div>
                                {changeLocation && <div className='flex flex-col gap-1'>
                                    <div className='flex flex-row gap-1'>
                                        <input type="text" className={'w-full ' + (errorPickupPoints != null ? '!border-1 !border-red-600' : '')} placeholder={t("ship_my_clothes.zipcode")} value={mapLocationAux.zipcode} onChange={(e) => setMapLocationAux({ ...mapLocationAux, zipcode: e.target.value })} />
                                        <select placeholder={t("ship_my_clothes.country")} name="countries" id="country" value={mapLocationAux.country_code} onChange={(e) => setMapLocationAux({ ...mapLocationAux, country_code: e.target.value })}
                                            className='appearance-none w-full '>
                                            <option value="" disabled selected hidden >{t("ship_my_clothes.country")}</option>
                                            {shippingCountries.map((country, index) => (
                                                <option key={index} value={country?.country_from_code}>{country?.country_from_name}</option>
                                            ))}
                                        </select>
                                        <button onClick={() => getPickupPoints(mapLocationAux.zipcode, mapLocationAux.country_code)} className='button secundary-button'><GrFormNextLink /></button>
                                    </div>
                                    {errorPickupPoints != null && <span className='flex items-center gap-1 text-sm text-error'><MdError /><span className='text-sm text-error'>{errorPickupPoints}</span></span>}
                                </div>}
                            </div>
                            <div className='w-full h-full relative'>
                                <div ref={mapContainer} className="w-full h-full map-container absolute" />
                            </div>
                            {shipClothesAux.pickuppoint_id && <div className='h-auto flex gap-1 items-center'>
                                <img src="https://res.cloudinary.com/dtqdhtmii/image/upload/v1712155756/SelectedPointer-1_poiya7.png" className='h-[50px] w-auto'></img>
                                <div className='flex flex-col text-sm'>
                                    <span className='font-bold'>{shipClothesAux.pickuppoint_id?.title}</span>
                                    <span>{shipClothesAux.pickuppoint_id?.street}</span>
                                    <span>{shipClothesAux.pickuppoint_id?.zipcode}, {shipClothesAux.pickuppoint_id?.city}</span>
                                </div>
                            </div>}
                        </div>
                    }
                </div>
                {deliveryOption == 1 && <div className='pb-3 w-full flex justify-end'>
                    <button onClick={goToNextStep} disabled={deliveryOption == 1 && !shipClothesAux.pickuppoint_id} className='px-4 w-auto button primary-button flex gap-2 items-center justify-center'>{t("geral.next")} <GrLinkNext /></button>
                </div>}
            </div>
        </div >
    );
}