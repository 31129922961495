import { useTranslation } from 'react-i18next';

import { FaCheckCircle } from "react-icons/fa";

export default function Home() {
    const { t } = useTranslation()

    return (
        <div className='w-full h-full page-no-header page-footer-buttons'>
            <div className='w-full h-full flex flex-col gap-4'>
                <div className='w-full flex flex-col gap-3 text-center'>
                    <h1 className='uppercase'>{t("home.how_it_works")}</h1>
                    <span className='text-extra'>{t("home.how_it_works_text")}</span>
                </div>
                <div className='py-4 flex flex-col gap-3'>
                    <div className='card flex gap-2'>
                        <div className='py-1'><FaCheckCircle className='w-4 h-4 text-primary' /></div>
                        <div className='flex flex-col gap-1'>
                            <div className='text-xl uppercase'>{t("home.how_it_works_1")}</div>
                            <div className='text-extra'>{t("home.how_it_works_1_text")}</div>
                        </div>
                    </div>
                    <div className='card flex gap-2'>
                        <div className='py-1'><FaCheckCircle className='w-4 h-4 text-primary' /></div>
                        <div className='flex flex-col gap-1'>
                            <div className='text-xl uppercase'>{t("home.how_it_works_2")}</div>
                            <div className='text-extra'>{t("home.how_it_works_2_text")}</div>
                        </div>
                    </div>
                    <div className='card flex gap-2'>
                        <div className='py-1'><FaCheckCircle className='w-4 h-4 text-primary' /></div>
                        <div className='flex flex-col gap-1'>
                            <div className='text-xl uppercase'>{t("home.how_it_works_3")}</div>
                            <div className='text-extra'>{t("home.how_it_works_3_text")}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}