import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { FaRegAddressCard, FaUnlockAlt } from "react-icons/fa";
import { AiOutlinePoweroff } from "react-icons/ai";
import { FaCircleUser } from "react-icons/fa6";
import '../../App.css';
import { useEffect } from 'react';

export default function MyAccount() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const userLogin = localStorage.getItem("userLogin") ? JSON.parse(localStorage.getItem("userLogin")) : null
    const userData = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : null

    function logOut() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + userLogin.Token);
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + '/customers/logout', requestOptions)
            .then(async response => {
                if (response.ok) {
                    return response.json();
                } else {
                    var error = await response.text();
                    return Promise.reject({ status: response.status, message: error });
                }
            })
            .then(result => {
                localStorage.clear()
                navigate("/login-account")
            })
            .catch(error => {
                if (error.status == 401) {
                    localStorage.clear()
                    navigate("/login-account")
                }
            })
    }

    return (
        <div className='page-header-close page-footer-buttons'>
            <div className='flex flex-col gap-3'>
                <div>{t("my_account.hi")}, {userLogin?.FirstName} {userLogin?.LastName}</div>
                <div className='flex justify-between items-center my-3'>
                    <FaCircleUser className='w-16 h-16 text-primary' />
                    <div className='flex flex-col items-end justify-end'>
                        <div className='text-primary font-semibold'>{userData?.customer?.EmailAddress}</div>
                        {/* <div className='text-sm text-extra'>created at 18-03-2023</div> */}
                    </div>
                </div>
                <div className='w-full border-b border-gray-300'></div>
            </div>
            <div className='flex flex-col gap-3 mt-6'>
                <Link to={"/my-account/my-address-book"} className='w-fit flex gap-2 items-center'>
                    <FaRegAddressCard className='w-4 h-4' />
                    <div>{t("my_account.address_book")}</div>
                </Link>
                <Link to="/my-account/change-password" className='w-fit flex gap-2 items-center'>
                    <FaUnlockAlt className='w-4 h-4' />
                    <div>{t("my_account.change_pwd")}</div>
                </Link>
                <button onClick={logOut} className='md:hidden w-fit flex gap-2'>
                    <AiOutlinePoweroff className='w-5 h-5' />
                    <div>{t("geral.log_out")}</div>
                </button>
            </div>
        </div>
    );
}