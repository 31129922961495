import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { IoCheckmarkCircleOutline } from "react-icons/io5";
import '../../App.css';

export default function Step5() {
    const { t } = useTranslation()

    return (
        <div className='w-full h-full page-no-header page-footer-buttons'>
            <div className='w-full h-full flex flex-col justify-center items-center gap-6'>
                <div><IoCheckmarkCircleOutline className='w-24 h-24' /></div>
                <div className='flex flex-col justify-center items-center text-center gap-1'>
                    <h2>{t("ship_my_clothes.success_submission")}</h2>
                    <span>{t("ship_my_clothes.receive_email_submission")}<br></br><span className='text-primary'>{t("ship_my_clothes.receive_email_submission_cont")}</span></span>
                </div>
                <Link to="/my-account/my-activity" className='w-9/12 button secundary-button flex gap-2 items-center justify-center'>{t("ship_my_clothes.my_account")}</Link>
            </div>
        </div>
    );
}