import { Canvas } from '@react-three/fiber'
import { useGLTF, OrbitControls, Stage } from '@react-three/drei';

function BagModel() {
  const { scene } = useGLTF("../coffee-bag-005.glb")
  return <primitive object={scene} />
}

export default function BagModel3D() {

  return (
    <Canvas className="small-canvas" dpr={[1, 2]} shadows camera={{ fov: 80 }}>
      <OrbitControls autoRotate enableZoom={false} maxPolarAngle={Math.PI / 2} minPolarAngle={Math.PI / 2} enablePan={false} />
        <Stage>
          <BagModel scale={0.01} />
        </Stage>
    </Canvas>

  );
}