import { Routes, Route, Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Header from "./components/Header";
import Footer from "./components/Footer";

import PrivateRoute from "./PrivateRoute";
import Home from "./pages/Home";
import Login from "./pages/Login";
import MyAccount from './pages/MyAccount/MyAccount';
import MyAddressBook from "./pages/MyAccount/MyAddressBook";
import ChangePassword from './pages/MyAccount/ChangePassword';
import MyActivity from "./pages/MyAccount/MyActivity";
import NewAddress from "./pages/MyAccount/NewAddress";
import EditAddress from "./pages/MyAccount/EditAddress";
import Step1 from "./pages/ShipMyClothes/Step1";
import Step2 from "./pages/ShipMyClothes/Step2";
import Step3 from "./pages/ShipMyClothes/Step3";
import Step4 from "./pages/ShipMyClothes/Step4";
import Step5 from "./pages/ShipMyClothes/Step5";
import PrivacyPolicy from "./pages/LegalPages/PrivacyPolicy";
import TermsAndConditions from "./pages/LegalPages/TermsAndConditions";

import background from './assets/background.png'
import './App.css';
import { useEffect } from "react";

function App() {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const userLogin = localStorage.getItem("userLogin") ? JSON.parse(localStorage.getItem("userLogin")) : null

  useEffect(() => {
    i18n.changeLanguage(process.env.REACT_APP_LANG)
  }, [])

  return (
    <div className="App flex flex-row justify-between overflow-hidden">
      <img src={background} className="z-10 absolute inset-0 w-full h-auto md:h-full"></img>
      <div className="z-20 AppScreen fixed w-full md:w-auto h-[calc(100%_-_70px)] flex flex-col items-center justify-start overflow-y-auto">
        <Header />
        <div className="p-3 z-30 w-full h-[calc(100%_-_2rem)] md:h-[calc(100%_-_2rem_-_40px)] flex justify-center overflow-y-auto">
          <div className="AppContainer relative">
            <Routes>
              <Route path="/login-account" element={<Login />}></Route>
              <Route path="/" element={<Home />}></Route>
              <Route element={<PrivateRoute />}>
                <Route path="/my-account">
                  <Route index element={<MyAccount />} />
                  <Route path="/my-account/my-activity" element={<MyActivity />}></Route>
                  <Route path="/my-account/change-password" element={<ChangePassword />}></Route>
                  <Route path="/my-account/my-address-book">
                    <Route index element={<MyAddressBook />} />
                    <Route path="/my-account/my-address-book/new-address" element={<NewAddress />}></Route>
                    <Route path="/my-account/my-address-book/edit-address/:id" element={<EditAddress />}></Route>
                  </Route>
                </Route>
                <Route path="/ship-my-clothes" element={<Step1 />}></Route>
                <Route path="/ship-my-clothes/choose-delivery" element={<Step2 />}></Route>
                <Route path="/ship-my-clothes/choose-delivery/delivery" element={<Step3 />}></Route>
                <Route path="/ship-my-clothes/choose-delivery/delivery/summary" element={<Step4 />}></Route>
                <Route path="/ship-my-clothes/choose-delivery/delivery/summary/submit" element={<Step5 />}></Route>
                <Route path="*" element={<MyActivity />}></Route>
              </Route>
              <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
              <Route path="/terms-and-conditions" element={<TermsAndConditions />}></Route>
            </Routes>
          </div>
        </div>
      </div>
      <Footer />
      <div className='z-30 absolute bottom-0 py-2 w-full hidden md:flex justify-center items-center'>
        <div className="footer-web-links flex gap-6 justify-center items-center">
          <Link to="/" className='text-primary'>{t('legal_pages.how_it_works')}</Link>
          <Link to="privacy-policy">{t('legal_pages.privacy_policy')}</Link>
          <Link to="terms-and-conditions">{t('legal_pages.terms_conditions')}</Link>
        </div>
      </div>
    </div >
  );
}

export default App;
