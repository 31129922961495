import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Login from '../components/Login'
import BagModel3D from "./BagModel3D";

export default function LoginPage () {
    const { t } = useTranslation()
    const [loginAccount, setLoginAccount] = useState(true)

    function changeLoginAccount() {
        setLoginAccount(!loginAccount)
    }

    return (
        <div className='pb-6 login-page page-no-header page-footer-links App'>
            {loginAccount && <>
                <div className="w-full h-auto max-h-[200px] relative flex justify-center items-center">
                    <BagModel3D></BagModel3D>
                </div>
                <div className='flex justify-center items-center mb-4'>
                    <h1 className='text-center'>{t("login.login_text")}<br></br>{t("login.login_text_cont")}</h1>
                </div>
            </>}
            <Login loginAccount={loginAccount} changeLoginAccount={changeLoginAccount}></Login>
        </div>
    )
}