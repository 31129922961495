import { useEffect, useState } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import Address from '../../components/Address';
import '../../App.css';

export default function EditAddress() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { id } = useParams();
    const userLogin = localStorage.getItem("userLogin") ? JSON.parse(localStorage.getItem("userLogin")) : null
    const userData = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : null
    const [addressEdit, setAddressEdit] = useState(null)
    const [callingCode, setCallingCodes] = useState([])
    const [errorAddressInvalid, setErrorAddressInvalid] = useState(false)

    useEffect(() => {
        var address = userData?.address.find(address => address.AddressID == id)
        if (address.PhoneNumber.split(' ').length > 1) {
            address.callingCode = address.PhoneNumber.split(' ')[0]
            address.PhoneNumber = address.PhoneNumber.split(' ')[1]
        }
        setAddressEdit(address)
    }, [])

    function editAddress() {
        setErrorAddressInvalid(false)
        if (addressEdit.FirstName && addressEdit.LastName && addressEdit.AddressLine1 && addressEdit.callingCode && addressEdit.PhoneNumber && addressEdit.PhoneNumber.length == 9 && addressEdit.ZIPCode && addressEdit.City && addressEdit.CountryID) {
            addressEdit.DefaultAddress = Number(addressEdit.DefaultAddress)
            if (addressEdit.DefaultAddress === 1) {
                userData.address.map(addressLine => {
                    if (addressLine.AddressID !== addressEdit.AddressID) {
                        addressLine.DefaultAddress = 0
                    }
                })
            }
            var phoneNumberAux = addressEdit.PhoneNumber
            if (addressEdit.PhoneNumber.split(' ').length > 1) {
                phoneNumberAux = addressEdit.PhoneNumber.split(' ')[1]
            }
            addressEdit.PhoneNumber = addressEdit.callingCode + ' ' + phoneNumberAux
            var addressIndex = userData.address.findIndex(addressLine => addressLine.AddressID == id)
            userData.address[addressIndex] = addressEdit
            updateUser(userData)
        } else {
            setErrorAddressInvalid(true)
        }
    }
    async function updateUser(userData) {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + userLogin.Token);
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(userData)
        };

        await fetch(process.env.REACT_APP_URL + '/customers/storefront', requestOptions)
            .then(async response => {
                if (response.ok) {
                    return response.json();
                } else {
                    var error = await response.text();
                    return Promise.reject({ status: response.status, message: error });
                }
            })
            .then(response => {
                userData = response
                localStorage.setItem("userData", JSON.stringify(response))
                navigate("/my-account/my-address-book")
            })
            .catch(error => {
                if (error.status == 401) {
                    localStorage.clear()
                    navigate("/login-account")
                }
            })
    }

    return (
        <div className='w-full h-full page-no-footer'>
            <div className='w-full h-full flex flex-col gap-3'>
                <h2>{t("my_account.my_address_book.edit_address")}</h2>
                <div className='card w-full flex flex-col justify-between items-center gap-4'>
                    {addressEdit && <Address errorAddressInvalid={errorAddressInvalid} address={addressEdit} setAddress={setAddressEdit} callingCode={callingCode} setCallingCodes={setCallingCodes}></Address>}
                    <div className='w-full flex gap-2 justify-start'>
                        <button onClick={editAddress} className='w-auto button secundary-button'>{t("geral.save")}</button>
                        <Link to="/my-account/my-address-book" className='w-auto button extra-button'>{t("geral.cancel")}</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}