import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { MdError } from "react-icons/md";

export default function Address(props) {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [defaultAddressAux, setDefaultAddressAux] = useState(props.address.DefaultAddress)
    const [countries, setCountries] = useState([])

    useEffect(() => {
        getCountryCallingCodes()
        getCountries()
    }, [])

    function getCountryCallingCodes() {
        var myHeaders = new Headers();

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch('https://restcountries.com/v3.1/all?fields=name,capital,idd,cca2', requestOptions)
            .then(async response => {
                if (response.ok) {
                    return response.json();
                } else {
                    var error = await response.text();
                    return Promise.reject({ status: response.status, message: error });
                }
            })
            .then(async response => {
                var callingCodesSort = response.sort((a, b) => a.cca2.localeCompare(b.cca2));
                props.setCallingCodes(callingCodesSort)
            })
            .catch(error => {
                if (error.status == 401) {
                    localStorage.clear()
                    navigate("/login-account")
                }
            })
    }
    function getCountries() {
        var myHeaders = new Headers();

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + '/customers/storefront/countries?id_brand=' + process.env.REACT_APP_ID_BRAND, requestOptions)
            .then(response => response.json())
            .then(response => {
                setCountries(response)
                if (!props.address.CountryID) {
                    var country = response.find(country => country.country_name == "Portugal")
                    props.setAddress({ ...props.address, CountryID: country.country_id })
                }
            })
            .catch(error => {
                if (error.status == 401) {
                    localStorage.clear()
                    navigate("/login-account")
                }
            })
    }

    return (
        <div className='w-full flex flex-col gap-3'>
            <div className='w-full flex gap-3'>
                <input autoComplete='true' type="text" placeholder={t("my_account.my_address_book.first_name")} value={props.address.FirstName} onChange={(e) => props.setAddress({ ...props.address, FirstName: e.target.value })}
                    className={'w-full outline-black ' + (props.errorAddressInvalid && !props.address.FirstName ? '!border-1 !border-red-600' : '')} />
                <input autoComplete='true' type="text" placeholder={t("my_account.my_address_book.last_name")} value={props.address.LastName} onChange={(e) => props.setAddress({ ...props.address, LastName: e.target.value })}
                    className={'w-full outline-black ' + (props.errorAddressInvalid && !props.address.LastName ? '!border-1 !border-red-600' : '')} />
            </div>
            <input autoComplete='true' type="text" placeholder={t("my_account.my_address_book.address1")} value={props.address.AddressLine1} onChange={(e) => props.setAddress({ ...props.address, AddressLine1: e.target.value })}
                className={'outline-black ' + (props.errorAddressInvalid && !props.address.AddressLine1 ? '!border-1 !border-red-600' : '')} />
            <input autoComplete='true' type="text" placeholder={t("my_account.my_address_book.address2")} value={props.address.AddressLine2} onChange={(e) => props.setAddress({ ...props.address, AddressLine2: e.target.value })}
                className={'outline-black '} />
            <div className='w-full flex gap-3'>
                <select name="callingcode" id="callingcode" value={props.address.callingCode} onChange={(e) => props.setAddress({ ...props.address, callingCode: e.target.value })}
                    className={"w-full outline-black appearance-none " + (props.errorAddressInvalid && !props.address.callingCode ? '!border-1 !border-red-600' : '')}>
                    <option hidden value="">{t("my_account.my_address_book.calling_code")}</option>
                    {props.callingCode?.map((country, index) => (
                        <option key={index} value={country.idd.root + (country.idd.suffixes.length == 1 ? country.idd.suffixes[0] : '')}>{country.cca2}: {country.idd.root}{country.idd.suffixes.length == 1 ? country.idd.suffixes[0] : ''}</option>
                    ))}
                </select>
                <input autoComplete='true' type="number" placeholder={t("my_account.my_address_book.phone_number")} value={props.address.PhoneNumber} onChange={(e) => props.setAddress({ ...props.address, PhoneNumber: e.target.value })}
                    className={'w-full outline-black ' + (props.errorAddressInvalid && (!props.address.PhoneNumber || props.address.PhoneNumber.length != 9) ? '!border-1 !border-red-600' : '')} />
            </div>
            <div className='w-full flex gap-3'>
                <input autoComplete='true' type="text" placeholder={t("my_account.my_address_book.zip_code")} value={props.address.ZIPCode} onChange={(e) => props.setAddress({ ...props.address, ZIPCode: e.target.value })}
                    className={'w-full outline-black ' + (props.errorAddressInvalid && !props.address.ZIPCode ? '!border-1 !border-red-600' : '')} />
                <input autoComplete='true' type="text" placeholder={t("my_account.my_address_book.city")} value={props.address.City} onChange={(e) => props.setAddress({ ...props.address, City: e.target.value })}
                    className={'w-full outline-black ' + (props.errorAddressInvalid && !props.address.City ? '!border-1 !border-red-600' : '')} />
            </div>
            <select name="countries" id="country" value={props.address.CountryID} onChange={(e) => props.setAddress({ ...props.address, CountryID: e.target.value })}
                className={"w-full outline-black appearance-none " + (props.errorAddressInvalid && !props.address.CountryID ? '!border-1 !border-red-600' : '')}>
                <option hidden value="">{t("my_account.my_address_book.country")}</option>
                {countries?.map((country, index) => (
                    <option key={index} value={country?.country_id}>{country?.country_name}</option>
                ))}
            </select>
            <span className='-mt-1 flex items-center gap-1 text-sm text-error'>{false ? <><MdError /><span className='text-sm text-error'>{t("errors.invalid_credentials")}</span></> :
                (props.errorAddressInvalid && (!props.address.FirstName || !props.address.LastName || !props.address.AddressLine1 || !props.address.callingCode ||
                    !props.address.PhoneNumber || props.address.PhoneNumber.length != 9 || !props.address.ZIPCode || !props.address.City || !props.address.CountryID)) ?
                    <><MdError /><span className='text-sm text-error'>{t("errors.required_fields")}</span></> :
                    <span> </span>}
            </span>
            <div className='input-simple-checkbox flex'>
                <input type="checkbox" disabled={defaultAddressAux} className="checkbox" id="defaultAddress" name="defaultAddress" value={props.address.DefaultAddress} checked={props.address.DefaultAddress} onChange={(e) => props.setAddress({ ...props.address, DefaultAddress: !props.address.DefaultAddress })}></input>
                <label htmlFor="defaultAddress" className="ml-1">{t("my_account.my_address_book.default_address")}</label>
            </div>
        </div>
    )
}