import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleLogin } from "@react-oauth/google"
import { useTranslation } from 'react-i18next';

import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { MdError } from "react-icons/md";

export default function Login(props) {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const userLogin = localStorage.getItem("userLogin") ? JSON.parse(localStorage.getItem("userLogin")) : null
    const [userAccount, setUserAccount] = useState({ email: '', pwd: '' })
    const [newUserAccount, setNewUserAccount] = useState({ firstName: '', lastName: '', email: '', pwd: '', pwdConfirm: '', terms: false })
    const [errorAccount, setErrorAccount] = useState(null)
    const [errorAccountInvalid, setErrorAccountInvalid] = useState(false)
    const [seePwd, setSeePwd] = useState(false)
    const [seeNewPwd, setSeeNewPwd] = useState(false)
    const [seePwdConfirm, setSeePwdConfirm] = useState(false)

    const responseMessage = (response) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        let raw = JSON.stringify({
            code: response.credential,
            cart: [],
            id_brand: process.env.REACT_APP_ID_BRAND
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + '/customers/google', requestOptions)
            .then(response => response.json())
            .then(result => {
                result.Customer.Token = result.Customer.token
                localStorage.setItem('userLogin', JSON.stringify(result.Customer))
                getUserData()
                navigate("/")
            })
            .catch(error => {
                if (error.status == 401) {
                    localStorage.clear()
                    navigate("/login-account")
                }
            })
    };

    function userLoginAccount() {
        setErrorAccount(null)
        setErrorAccountInvalid(false)
        if (props.loginAccount) {
            signInAccount()
        } else {
            registerAccount()
        }

    }

    function registerAccount() {
        setErrorAccount(null)
        setErrorAccountInvalid(false)
        if (newUserAccount.firstName && newUserAccount.lastName && newUserAccount.email && isValidEmail(newUserAccount.email) && newUserAccount.pwd && isValidPassword(newUserAccount.pwd) && newUserAccount.pwdConfirm && isValidConfirmPassword() && newUserAccount.terms) {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            let raw = JSON.stringify({
                first_name: newUserAccount.firstName,
                last_name: newUserAccount.lastName,
                EmailAddress: newUserAccount.email,
                password: newUserAccount.pwd,
                cart: [],
                id_brand: Number(process.env.REACT_APP_ID_BRAND)
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + '/customers/signin', requestOptions)
                .then(async response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        var error = await response.text();
                        error = error.slice(1, error.length - 1)
                        return Promise.reject({ status: response.status, message: error });
                    }
                })
                .then(result => {
                    result.Customer.Token = result.Customer.token
                    localStorage.setItem('userLogin', JSON.stringify(result.Customer))
                    getUserData()
                    navigate("/")
                })
                .catch(error => {
                    setErrorAccount(t("login.error_login"))
                    if (error.status == 401) {
                        localStorage.clear()
                        navigate("/login-account")
                    }
                });
        } else {
            setErrorAccountInvalid(true)
        }
    }
    function signInAccount() {
        setErrorAccount(null)
        if (userAccount.email && isValidEmail(userAccount.email) && userAccount.pwd) {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            let raw = JSON.stringify({
                email: userAccount.email,
                password: userAccount.pwd,
                cart: [],
                id_brand: Number(process.env.REACT_APP_ID_BRAND)
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + '/customers/login', requestOptions)
                .then(async response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        var error = await response.text();
                        return Promise.reject({ status: response.status, message: error });
                    }
                })
                .then(result => {
                    localStorage.setItem('userLogin', JSON.stringify(result.Customer))
                    getUserData()
                    navigate("/")
                })
                .catch(error => {
                    setErrorAccount(t("login.error_login"))
                    if (error.status == 401) {
                        localStorage.clear()
                        navigate("/login-account")
                    }
                });
        } else {
            setErrorAccountInvalid(true)
        }
    }
    function getUserData() {
        var userLogin = JSON.parse(localStorage.getItem("userLogin"))
        if (userLogin?.Token) {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + userLogin?.Token);

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + '/customers/storefront', requestOptions)
                .then(async response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        var error = await response.text();
                        return Promise.reject({ status: response.status, message: error });
                    }
                })
                .then(response => {
                    localStorage.setItem("userData", JSON.stringify(response))
                })
                .catch(error => {
                    if (error.status == 401) {
                        localStorage.clear()
                        navigate("/login-account")
                    }
                })
        }
    }
    function isValidConfirmPassword() {
        return newUserAccount.pwd === newUserAccount.pwdConfirm
    }
    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }
    function isValidPassword(password) {
        return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-.:;?@[\\\]_`{|}~])[A-Za-z\d!"#$%&'()*+,-.:;?@[\\\]_`{|}~]{8,30}$/.test(password);
    }

    return (
        <div className="w-full flex flex-col justify-center items-center gap-4">
            <div className='w-full flex flex-col gap-3'>
                {props.loginAccount ? <>
                    <input autoComplete='true' type="email" placeholder={t("login.example_email")} value={userAccount.email} onChange={(e) => setUserAccount({ ...userAccount, email: e.target.value })}
                        onKeyDown={(e) => { if (e.key == 'Enter') { userLoginAccount() } }}
                        className={'outline-black ' + (errorAccountInvalid && (!userAccount.email || !isValidEmail(userAccount.email)) ? '!border-1 !border-red-600' : '')} />
                    <div className='relative flex '>
                        <input type={seePwd ? "text" : "password"} placeholder='••••••••' value={userAccount.pwd} onChange={(e) => setUserAccount({ ...userAccount, pwd: e.target.value })}
                            onKeyDown={(e) => { if (e.key == 'Enter') { userLoginAccount() } }}
                            className={'w-full !pr-8 outline-black ' + (errorAccountInvalid && !userAccount.pwd ? '!border-1 !border-red-600' : '')} />
                        <button onClick={() => setSeePwd(!seePwd)} className='absolute right-2 w-auto h-full flex items-center'>
                            {seePwd ? <FaRegEyeSlash className='w-4 h-4' /> :
                                <FaRegEye className='w-4 h-4' />}
                        </button>
                    </div>
                    <span className='flex items-center gap-1 text-sm text-error'>{errorAccount ? <><MdError /><span className='text-sm text-error'>{errorAccount}</span></> :
                        errorAccountInvalid && (!userAccount.email || !isValidEmail(userAccount.email)) ? <><MdError /><span className='text-sm text-error'>{t("errors.error_email")}</span></> :
                            errorAccountInvalid && !userAccount.pwd ? <><MdError /><span className='text-sm text-error'>{t("errors.error_password")}</span></> :
                                <span> </span>}
                    </span>
                </>
                    :
                    <>
                        <input autoComplete='true' type="text" placeholder={t("login.first_name")} value={newUserAccount.firstName} onChange={(e) => setNewUserAccount({ ...newUserAccount, firstName: e.target.value })}
                            className={'outline-black ' + (errorAccountInvalid && !newUserAccount.firstName ? '!border !border-red-600' : '')} />
                        <input autoComplete='true' type="text" placeholder={t("login.last_name")} value={newUserAccount.lastName} onChange={(e) => setNewUserAccount({ ...newUserAccount, lastName: e.target.value })}
                            className={'outline-black ' + (errorAccountInvalid && !newUserAccount.lastName ? '!border !border-red-600' : '')} />
                        <input autoComplete='true' type="email" placeholder={t("login.example_email")} value={newUserAccount.email} onChange={(e) => setNewUserAccount({ ...newUserAccount, email: e.target.value })}
                            className={'outline-black ' + (errorAccountInvalid && (!newUserAccount.email || !isValidEmail(newUserAccount.email)) ? '!border !border-red-600' : '')} />
                        <div className='relative flex '>
                            <input type={seeNewPwd ? "text" : "password"} placeholder='••••••••' value={newUserAccount.pwd} onChange={(e) => setNewUserAccount({ ...newUserAccount, pwd: e.target.value })}
                                className={'w-full !pr-8 outline-black ' + (errorAccountInvalid && (!newUserAccount.pwd || !isValidPassword(newUserAccount.pwd)) ? '!border !border-red-600' : '')} />
                            <button onClick={() => setSeeNewPwd(!seeNewPwd)} className='absolute right-2 w-auto h-full flex items-center'>
                                {seeNewPwd ? <FaRegEyeSlash className='w-4 h-4' /> :
                                    <FaRegEye className='w-4 h-4' />}
                            </button>
                        </div>
                        <div className='relative flex '>
                            <input type={seePwdConfirm ? "text" : "password"} placeholder='••••••••' value={newUserAccount.pwdConfirm} onChange={(e) => setNewUserAccount({ ...newUserAccount, pwdConfirm: e.target.value })}
                                className={'w-full !pr-8 outline-black ' + (errorAccountInvalid && (!newUserAccount.pwdConfirm || (newUserAccount.pwd !== newUserAccount.pwdConfirm)) ? '!border !border-red-600' : '')} />
                            <button onClick={() => setSeePwdConfirm(!seePwdConfirm)} className='absolute right-2 w-auto h-full flex items-center'>
                                {seePwdConfirm ? <FaRegEyeSlash className='w-4 h-4' /> :
                                    <FaRegEye className='w-4 h-4' />}
                            </button>
                        </div>
                        <div className='input-simple-checkbox flex'>
                            <input type="checkbox" className={"checkbox " + (errorAccountInvalid && !newUserAccount.terms ? '!border !border-red-600' : '')} id="accept-terms" name="accept-terms" onChange={() => setNewUserAccount({ ...newUserAccount, terms: !newUserAccount.terms })}></input>
                            <label htmlFor="accept-terms" className={"ml-1 " + (errorAccountInvalid && !newUserAccount.terms ? 'text-error' : '')}>{t("login.terms_conditions")}</label>
                        </div>
                        <span className='-mt-1 flex items-center gap-1 text-sm text-error'>{errorAccount ? <><MdError /><span className='text-sm text-error'>{errorAccount}</span></> :
                            errorAccountInvalid && (!newUserAccount.firstName || !newUserAccount.lastName || !newUserAccount.email || !newUserAccount.pwd || !newUserAccount.pwdConfirm || !newUserAccount.terms) ? <><MdError /><span className='text-sm text-error'>{t("errors.required_fields")}</span></> :
                                errorAccountInvalid && (!newUserAccount.email || !isValidEmail(newUserAccount.email)) ? <><MdError /><span className='text-sm text-error'>{t("errors.error_email")}</span></> :
                                    errorAccountInvalid && (!newUserAccount.pwd || !isValidPassword(newUserAccount.pwd)) ? <><MdError /><span className='text-sm text-error'>{t("errors.pwd_rules")}</span></> :
                                        errorAccountInvalid && (!isValidConfirmPassword()) ? <><MdError /><span className='text-sm text-error'>{t("errors.pwd_dont_match")}</span></> :
                                            <span></span>}
                        </span>
                    </>
                }
                <button onClick={userLoginAccount} className='button primary-button'>{props.loginAccount ? t("login.login") : t("login.sign_up")}</button>
            </div>
            <div className='w-full text-sm'>{props.loginAccount ? t("login.or_login_with") : t("login.or_register_with")}</div>
            <GoogleLogin onSuccess={responseMessage} width="342px" text="continue_with" type="icon" theme="filled" size="large" shape="pill" />
            {props.loginAccount ? <div className='w-full my-3 text-sm'>{t("login.dont_have_account")} <button onClick={() => { props.changeLoginAccount(false); setErrorAccount(null); setErrorAccountInvalid(false) }} className='text-primary font-medium'>{t("login.sign_up")}</button></div>
                : <button className='w-full my-3 text-sm' onClick={() => { props.changeLoginAccount(true); setErrorAccount(null); setErrorAccountInvalid(false) }}>{t("login.already_have_account")}</button>}
        </div>
    )
}