import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {
    const userLogin = localStorage.getItem("userLogin") ? JSON.parse(localStorage.getItem("userLogin")) : null

    if (!userLogin?.Token) return <Navigate to="/login-account" />;
    return <Outlet />;
};

export default PrivateRoute;