import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { IoHomeOutline, IoPersonOutline, IoBagOutline, IoSettingsOutline } from "react-icons/io5";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { AiOutlinePoweroff } from "react-icons/ai";

export default function Footer() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const [hideMenu, setHideMenu] = useState(true)
    const userLogin = localStorage.getItem("userLogin") ? JSON.parse(localStorage.getItem("userLogin")) : null

    function logOut() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + userLogin.Token);
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + '/customers/logout', requestOptions)
            .then(async response => {
                if (response.ok) {
                    return response.json();
                } else {
                    var error = await response.text();
                    return Promise.reject({ status: response.status, message: error });
                }
            })
            .then(result => {
                localStorage.clear()
                navigate("/login-account")
            })
            .catch(error => {
                if(error.status == 401){
                    localStorage.clear()
                    navigate("/login-account")
                }
            })
    }

    return (
        <footer className={"z-40 relative overflow-hidden " + (!hideMenu ? 'open-menu md:min-w-[190px]' : 'close-menu')}>
            <div className={'menuBar-icon hidden md:flex fixed bottom-3 ' + (!hideMenu ? 'left-[175px]' : 'left-[40px]')}>
                <button className='bg-white border border-gray-300 rounded-full p-1' onClick={() => setHideMenu(!hideMenu)}>{hideMenu ? <GrFormNext /> : <GrFormPrevious />}</button>
            </div>
            <div className='py-2 footer-links w-full flex md:hidden flex-col justify-center items-center'>
                <div className='text-primary'>{t('legal_pages.how_it_works')}</div>
                <Link to="privacy-policy">{t('legal_pages.privacy_policy')}</Link>
                <div>{t('legal_pages.terms_conditions')}</div>
            </div>
            <div className="py-2 md:py-11 md:px-4 md:min-h-screen border-t md:border-t-0 md:border-r border-gray-300 footer-buttons flex md:flex-col md:h-full md:bg-white md:!justify-between md:gap-8 items-center" style={{ justifyContent: 'space-around' }}>
                <div className='w-full flex md:flex-col md:h-full md:!justify-start md:gap-8 items-center' style={{ justifyContent: 'space-around' }}>
                    <Link to="/" className={"flex flex-col md:flex-row w-full justify-center md:justify-start items-center gap-2 " + (pathname == "/" ? 'text-primary font-semibold' : '')}>
                        <IoHomeOutline className="h-5 w-5 md:h-6 md:w-6 " />
                        <div className={hideMenu ? 'md:hidden' : 'flex whitespace-nowrap'}>{t("pages.home")}</div>
                    </Link>
                    {!userLogin?.Token && <Link to="/login-account" className={"flex flex-col md:flex-row w-full justify-center md:justify-start items-center gap-2 " + (pathname.startsWith("/login-account") ? 'text-primary font-semibold' : '')}>
                        <IoPersonOutline className="h-5 w-5 md:h-6 md:w-6 " />
                        <div className={hideMenu ? 'md:hidden' : 'flex whitespace-nowrap'}>{t("pages.login_account")}</div>
                    </Link>
                    }
                    {userLogin?.Token && <>
                        <Link to="/ship-my-clothes" className={"flex flex-col md:flex-row w-full justify-center md:justify-start items-center gap-2 " + (pathname.startsWith("/ship-my-clothes") ? 'text-primary font-semibold' : '')}>
                            <IoBagOutline className="h-5 w-5 md:h-6 md:w-6 " />
                            <div className={hideMenu ? 'md:hidden' : 'flex whitespace-nowrap'}>{t("pages.ship_my_clothes_small")}</div>
                        </Link>
                        <Link to="/my-account/my-activity" className={"flex flex-col md:flex-row w-full justify-center md:justify-start items-center gap-2 " + (pathname == "/my-account/my-activity" ? 'text-primary font-semibold' : '')}>
                            <IoPersonOutline className="h-5 w-5 md:h-6 md:w-6" />
                            <div className={hideMenu ? 'md:hidden' : 'flex whitespace-nowrap'}>{t("pages.me")}</div>
                        </Link>
                        <Link to="/my-account" className={"hidden md:flex flex-col md:flex-row md:w-full justify-center md:justify-start items-center gap-2 " + (pathname.startsWith("/my-account") && pathname !== "/my-account/my-activity" ? 'text-primary font-semibold' : '')}>
                            <IoSettingsOutline className="h-5 w-5 md:h-6 md:w-6" />
                            <div className={hideMenu ? 'md:hidden' : 'flex whitespace-nowrap'}>{t("pages.settings")}</div>
                        </Link>
                    </>}
                </div>
                {userLogin?.Token && <button onClick={logOut} className="hidden md:flex flex-col md:flex-row md:w-full justify-center md:justify-start items-center gap-2 ">
                    <AiOutlinePoweroff className="h-5 w-5 md:h-6 md:w-6" />
                    <div className={hideMenu ? 'md:hidden' : 'flex whitespace-nowrap'}>{t("geral.log_out")}</div>
                </button>}
            </div>
        </footer>
    )
}