import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import moment from "moment";

import { GrDownload, GrLogout } from "react-icons/gr";
import '../../App.css';

export default function MyActivity() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const userLogin = localStorage.getItem("userLogin") ? JSON.parse(localStorage.getItem("userLogin")) : null
    const [loadingSubmissions, setLoadingSubmissions] = useState(true)
    const [submissions, setSubmissions] = useState([])
    const [showMore, setShowMore] = useState(5)

    useEffect(() => {
        setLoadingSubmissions(true)
        getUserActivity()
    }, [])

    function logOut() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + userLogin.Token);
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + '/customers/logout', requestOptions)
            .then(async response => {
                if (response.ok) {
                    return response.json();
                } else {
                    var error = await response.text();
                    return Promise.reject({ status: response.status, message: error });
                }
            })
            .then(result => {
                localStorage.clear()
                navigate("/login-account")
            })
            .catch(error => {
                if (error.status == 401) {
                    localStorage.clear()
                    navigate("/login-account")
                }
            })
    }

    function getUserActivity() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + userLogin?.Token);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch('https://swyco-app-dev.azurewebsites.net/api/recycleclothes/getActivityData', requestOptions)
            .then(async response => {
                if (response.ok) {
                    return response.json();
                } else {
                    var error = await response.text();
                    return Promise.reject({ status: response.status, message: error });
                }
            })
            .then(response => {
                response[0].submissions.sort(function (a, b) {
                    // Turn your strings into dates, and then subtract them
                    // to get a value that is either negative, positive, or zero.
                    return new Date(b.submission_created_at) - new Date(a.submission_created_at);
                });
                setSubmissions(response[0].submissions)
                setLoadingSubmissions(false)
            })
            .catch(error => {
                if (error.status == 401) {
                    localStorage.clear()
                    navigate("/login-account")
                } else if (error.status == 500) {
                    setLoadingSubmissions(false)
                }
            })
    }
    function dowloadLabel(idLabel, labelBase64) {
        var a = document.createElement("a");
        a.href = "data:application/pdf;base64," + labelBase64;
        a.download = "label-" + idLabel + ".pdf";
        a.click();
    }

    return (
        <home className='h-full flex flex-col page-header-settings page-footer-buttons'>
            <div className='h-52 flex flex-col items-center' style={{ justifyContent: 'space-around' }}>
                <div>{t("my_account.my_activity.welcome")},</div>
                <h1>{userLogin?.FirstName} {userLogin?.LastName}</h1>
                <button className='flex gap-1 justify-center items-center' onClick={logOut}><GrLogout className='w-4 h-4' /> {t("geral.log_out")}</button>
            </div>
            <div className='h-[calc(100%_-_13rem)] flex flex-col gap-3 mt-3'>
                <div>{t("my_account.my_activity.my_activity")}</div>
                {!loadingSubmissions &&
                    <>
                        {submissions.length > 0 ?
                            <div className='w-full h-full overflow-y-auto scrollbar-small'>
                                <table className='w-full table'>
                                    <tbody>
                                        {submissions.slice(0, showMore)?.map((submission) => (
                                            <tr key={submission.submission_Id}>
                                                <td>#{submission.submission_Id}</td>
                                                <td>{moment(submission.submission_created_at).utc().format('DD MMMM YYYY')}</td>
                                                {submission.Tracking && <td className='text-primary'><a href={submission.track_link + submission.Tracking} target='_blank '>{submission.Tracking}</a></td>}
                                                {submission.label && <td><button onClick={() => dowloadLabel(submission.submission_Id, submission.label)}><GrDownload className='w-4 h-4' /></button></td>}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {showMore <= submissions.length &&
                                    <div className='w-full py-3 flex items-center justify-center'>
                                        <button onClick={() => setShowMore(showMore + 10)} className='font-bold hover:underline hover:offset-underline-2'>View more</button>
                                    </div>}
                            </div>
                            :
                            <div className='flex flex-col w-full items-center justify-center gap-3'>
                                <div className='text-extra'>{t("my_account.my_activity.no_activity")}</div>
                                <button to="/ship-my-clothes" className='!w-auto button primary-button'>{t("my_account.my_activity.start_activity")}</button>
                            </div>
                        }
                    </>
                }
            </div>
        </home>
    );
}