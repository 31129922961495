import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { MdError } from "react-icons/md";
import '../../App.css';

export default function ChangePassword() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [submittedNewPassword, setSubmittedNewPassword] = useState(false)
    const [invalidSubmittedNewPassword, setInvalidSubmittedNewPassword] = useState(false)
    const userLogin = localStorage.getItem("userLogin") ? JSON.parse(localStorage.getItem("userLogin")) : null
    const [newPassword, setNewPassword] = useState({ currentPassword: '', newPassword: '', confirmPassword: '' })
    const [seeNewPwd, setSeeNewPwd] = useState(false)
    const [seePwdConfirm, setSeePwdConfirm] = useState(false)

    function changePassword() {
        setSubmittedNewPassword(true)
        setInvalidSubmittedNewPassword(false)
        if (newPassword.currentPassword && newPassword.newPassword && newPassword.confirmPassword &&
            isValidPassword(newPassword.newPassword) && isValidConfirmPassword()) {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + userLogin.Token);
            myHeaders.append("Content-Type", "application/json");

            let raw = JSON.stringify({
                old_password: newPassword.currentPassword,
                new_password: newPassword.newPassword
            });

            var requestOptions = {
                method: 'PUT',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + '/customers/changepassword', requestOptions)
                .then(async response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        var error = await response.text();
                        return Promise.reject({ status: response.status, message: error });
                    }
                })
                .then(result => {
                    setSubmittedNewPassword(false)
                    navigate("/my-account")
                })
                .catch(error => {
                    setInvalidSubmittedNewPassword(true)
                    if(error.status == 401){
                        localStorage.clear()
                        navigate("/login-account")
                    }
                });
        }
    }
    function isValidPassword(password) {
        return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-.:;?@[\\\]_`{|}~])[A-Za-z\d!"#$%&'()*+,-.:;?@[\\\]_`{|}~]{8,30}$/.test(password);
    }
    function isValidConfirmPassword() {
        return newPassword.newPassword === newPassword.confirmPassword
    }

    return (
        <div className='w-full h-full page-no-footer'>
            <div className='w-full h-full flex flex-col gap-3'>
                <h2>{t("my_account.change_pwd")}</h2>
                <div className='card flex flex-col gap-4'>
                    <div className='w-full flex flex-col justify-start gap-3'>
                        <input type="password" placeholder={t("my_account.change_password.password")} value={newPassword.currentPassword} onChange={(e) => setNewPassword({ ...newPassword, currentPassword: e.target.value })}
                            className={'w-full outline-black ' + (submittedNewPassword && (!newPassword.currentPassword || invalidSubmittedNewPassword) ? '!border !border-red-600' : '')} />

                        <div className='relative flex '>
                            <input type={seeNewPwd ? "text" : "password"} placeholder={t("my_account.change_password.new_password")} value={newPassword.newPassword} onChange={(e) => setNewPassword({ ...newPassword, newPassword: e.target.value })}
                                className={'w-full outline-black ' + (submittedNewPassword && (!newPassword.newPassword || !isValidPassword(newPassword.newPassword)) ? '!border !border-red-600' : '')} />
                            <button onClick={() => setSeeNewPwd(!seeNewPwd)} className='absolute right-2 w-auto h-full flex items-center'>
                                {seeNewPwd ? <FaRegEyeSlash className='w-4 h-4' /> :
                                    <FaRegEye className='w-4 h-4' />}
                            </button>
                        </div>
                        <div className='relative flex '>
                            <input type={seePwdConfirm ? "text" : "password"} placeholder={t("my_account.change_password.confirm_password")} value={newPassword.confirmPassword} onChange={(e) => setNewPassword({ ...newPassword, confirmPassword: e.target.value })}
                                className={'w-full !pr-8 outline-black ' + (submittedNewPassword && (!newPassword.confirmPassword || !isValidConfirmPassword()) ? '!border !border-red-600' : '')} />
                            <button onClick={() => setSeePwdConfirm(!seePwdConfirm)} className='absolute right-2 w-auto h-full flex items-center'>
                                {seePwdConfirm ? <FaRegEyeSlash className='w-4 h-4' /> :
                                    <FaRegEye className='w-4 h-4' />}
                            </button>
                        </div>
                        <span className='-mt-1 flex items-center gap-1 text-sm text-error'>{invalidSubmittedNewPassword ? <><MdError /><span className='text-sm text-error'>{t("errors.invalid_credentials")}</span></> :
                            submittedNewPassword && (!newPassword.currentPassword || !newPassword.newPassword || !newPassword.confirmPassword) ? <><MdError /><span className='text-sm text-error'>{t("errors.required_fields")}</span></> :
                                submittedNewPassword && !isValidPassword(newPassword.newPassword) ? <><MdError /><span className='text-sm text-error'>{t("errors.pwd_rules")}</span></> :
                                    submittedNewPassword && !isValidConfirmPassword() ? <><MdError /><span className='text-sm text-error'>{t("errors.pwd_dont_match")}</span></> :
                                        <span> </span>}
                        </span>
                    </div>
                    <div className='w-full flex gap-2 justify-start'>
                        <button onClick={changePassword} className='w-auto button secundary-button'>{t("geral.save")}</button>
                        <Link to="/my-account" className='w-auto button extra-button'>{t("geral.cancel")}</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}