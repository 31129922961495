import { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { GrLinkNext } from "react-icons/gr";
import '../../App.css';
import NewAddress from '../MyAccount/NewAddress';

export default function Step2() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const userData = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : null
    const [shipClothesAux, setShipClothesAux] = useState(JSON.parse(localStorage.getItem("shipClothes")))

    function goToNextStep() {
        if (shipClothesAux?.address_id) {
            navigate("/ship-my-clothes/choose-delivery/delivery")
        }
    }

    useEffect(() => {
        localStorage.setItem("shipClothes", JSON.stringify(shipClothesAux))
    }, [shipClothesAux])

    return (
        <div className='w-full h-full page-footer-buttons'>
            <div className='w-full h-full flex flex-col justify-between gap-4'>
                <div className='w-full flex flex-col gap-3'>
                    <h2>{t("ship_my_clothes.delivery_address")}</h2>
                    {userData?.address?.length > 0 ? <select name="address" id="address" value={shipClothesAux.address_id} onChange={(e) => setShipClothesAux({ ...shipClothesAux, address_id: e.target.value })}
                        className="w-full outline-black appearance-none">
                        <option hidden value="">{t("ship_my_clothes.choose_address")}</option>
                        {userData?.address.map((address, index) => (
                            <option key={index} value={address.AddressID}>{address.AddressLine1}, {address.ZIPCode} {address.City}</option>
                        ))}
                        <option key={'new_address'} value="new_address">{t("my_account.my_address_book.new_address")}</option>
                    </select>
                        :
                        <NewAddress anotherpage={true}></NewAddress>
                        // <Link to="/my-account/my-address-book/new-address" className='button secundary-button'>{t("my_account.my_address_book.add_address")}</Link>
                    }
                    {shipClothesAux.address_id == "new_address" && <NewAddress anotherpage={true}></NewAddress>}
                </div>
                <div className='pb-3 w-full flex justify-end'>
                    <button onClick={goToNextStep} disabled={!shipClothesAux.address_id} className='px-4 w-auto button primary-button flex gap-2 items-center justify-center'>{t("geral.next")} <GrLinkNext /></button>
                </div>
            </div>
        </div>
    );
}