import { useEffect, useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';

import { GrClose } from "react-icons/gr";
import { IoSettingsOutline } from "react-icons/io5";
import { useTranslation } from 'react-i18next';

export default function Header() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const [headerNavLinks, setHeaderNavLinks] = useState([])

    useEffect(() => {
        var headerNavLinksItems = pathname.split("/")
        headerNavLinksItems.shift()
        var headerNavLinksItemsOri = pathname.split("/")
        headerNavLinksItemsOri.shift()
        headerNavLinksItems.map((item, index) => {
            var url = item
            if (index > 0) {
                url = headerNavLinksItemsOri.slice(0, index + 1).join("/")
            }
            var itemTrans = item.replaceAll("-", "_")
            if (Number(itemTrans)) {
                headerNavLinksItems[index] = { url: null, trans: null }
            } else {
                headerNavLinksItems[index] = { url: url, trans: itemTrans }
            }
        })
        setHeaderNavLinks(headerNavLinksItems)
    }, [pathname])

    function goBack() {
        var headerNavLinksItems = pathname.split("/")
        headerNavLinksItems.shift()
        headerNavLinksItems.pop()
        if (headerNavLinksItems[headerNavLinksItems.length - 1] == "edit-address") {
            headerNavLinksItems.pop()
        }
        navigate(headerNavLinksItems.join('/'))
    }

    return (
        <header className="z-20 w-full h-8 px-3 flex items-center">
            <div className='hidden md:flex header-navlinks flex gap-3'>
                <Link to="/" className='navlink text-sm'>{t("pages.home")}</Link>
                <div className='dot'>•</div>
                {headerNavLinks.map(item => (
                    <>
                        {item.url && <span className='flex gap-3'>
                            {(item.url).endsWith("/edit-address") ?
                                <span>
                                    <span className='navlink text-sm'>{t("pages." + item.trans)}</span>
                                </span>
                                :
                                <Link to={item.url} className='navlink text-sm'>{t("pages." + item.trans)}</Link>}
                            <div className='dot'>•</div>
                        </span>}
                    </>
                ))}
            </div>
            <div className="md:hidden header-button-close w-full h-full flex justify-end items-center">
                <GrClose onClick={goBack} className="w-3 h-3 cursor-pointer" />
            </div>
            <Link to="/my-account" className="hidden header-button-settings w-full h-full flex justify-end items-center cursor-pointer">
                <IoSettingsOutline className="w-auto h-auto" />
            </Link>
        </header >
    )
}