import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { FaTrash, FaPencilAlt } from "react-icons/fa";
import { FiPlus } from "react-icons/fi";
import '../../App.css';
import { useEffect, useState } from 'react';

export default function MyAddressBook() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const userLogin = localStorage.getItem("userLogin") ? JSON.parse(localStorage.getItem("userLogin")) : null
    const userData = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : null
    const [addressList, setAddressList] = useState([])

    useEffect(() => {
        setAddressList(userData.address)
    }, [])

    function deleteUserAddress(AddressID) {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + userLogin.Token);

        var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + '/customers/storefront/address?address_id=' + AddressID, requestOptions)
            .then(async response => {
                if (response.ok) {
                    return response.json();
                } else {
                    var error = await response.text();
                    return Promise.reject({ status: response.status, message: error });
                }
            })
            .then(response => {
                localStorage.setItem("userData", JSON.stringify(response))
                setAddressList(response.address)
            })
            .catch(error => {
                if (error.status == 401) {
                    localStorage.clear()
                    navigate("/login-account")
                }
            })
    }

    return (
        <div className='w-full h-full page-no-footer'>
            {/* <div className='absolute top-11 left-3 !px-3 button secundary-button flex gap-1 items-center'><FaAngleLeft /> Back</div> */}
            <div className='w-full h-full flex flex-col gap-3'>
                <div className='flex w-full justify-between'>
                    <h2>{t("my_account.address_book")}</h2>
                    <Link to="/my-account/my-address-book/new-address" className='hidden md:flex w-auto px-2 button text-button flex gap-2 items-center'><FiPlus className='w-5 h-5' />{t("my_account.my_address_book.address")}</Link>
                </div>
                <div className='w-full h-full flex justify-between items-center flex-col gap-4'>
                    <div className='w-full h-full flex flex-col gap-3'>
                        {addressList.map((address) => (
                            <div key={"address." + address.AddressID} className='w-full card '>
                                <div className='w-full h-full flex gap-3 justify-between'>
                                    <div className='w-full flex flex-col'>
                                        <div className='flex gap-3 items-center'>
                                            <div className='font-semibold'>{address.FirstName} {address.LastName}</div>
                                            {Boolean(address.DefaultAddress) && <div className='p-1 font-semibold text-xs text-primary bg-[var(--primary-bg-color)] rounded-md'>{t("geral.default")}</div>}
                                        </div>
                                        <div>{address.AddressLine1}</div>
                                        <div>{address.AddressLine2}</div>
                                        <div>{address.ZIPCode} {address.City}</div>
                                    </div>
                                    <div className="flex flex-col items-end text-sm justify-between">
                                        <Link className='flex gap-2 items-center' to={"/my-account/my-address-book/edit-address/" + address.AddressID}><FaPencilAlt className='w-4 h-4' /> <span className='hidden md:flex'>{t("geral.edit")}</span> </Link>
                                        <button className='flex gap-2 items-center text-orange' disabled={Boolean(address.DefaultAddress)} onClick={() => deleteUserAddress(address.AddressID)}><FaTrash className='w-4 h-4' /> <span className='hidden md:flex'>{t("geral.delete")}</span></button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <Link to="/my-account/my-address-book/new-address" className='block md:hidden w-full button secundary-button'>{t("my_account.my_address_book.add_address")}</Link>
                </div>
            </div>
        </div>
    );
}