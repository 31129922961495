import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { GrLinkNext } from "react-icons/gr";
import '../../App.css';

export default function Step1() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const userLogin = localStorage.getItem("userLogin") ? JSON.parse(localStorage.getItem("userLogin")) : null
    const shipClothes = localStorage.getItem("shipClothes") ?
        JSON.parse(localStorage.getItem("shipClothes")) : { qstn1: [], qstn2: null, qstn3: [], address_id: null, pickuppoint_id: null }
    const [shipClothesAux, setShipClothesAux] = useState(shipClothes)
    const [questions, setQuestions] = useState({ qstn1: [], qstn2: [], qstn3: [] })
    const [loadingQuestions, setLoadingQuestions] = useState(true)
    
    // Mapear os valores de questions.qstn1 e criar um array de opções para o select
    const options = questions.qstn1.map(option => ({
        value: option.id.toString(),
        label: option.description
    }));

    useEffect(() => {
        getQuestions()
    }, [])

    function getQuestions() {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + userLogin.Token);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch('https://swyco-app-dev.azurewebsites.net/api/recycleclothes/getQuestionsData', requestOptions)
            .then(async response => {
                if (response.ok) {
                    return response.json();
                } else {
                    var error = await response.text();
                    return Promise.reject({ status: response.status, message: error });
                }
            })
            .then(response => {
                setQuestions(response)
                setLoadingQuestions(false)
            })
            .catch(error => {
                if(error.status == 401){
                    localStorage.clear()
                    navigate("/login-account")
                }
            });
    }
    useEffect(() => {
        localStorage.setItem("shipClothes", JSON.stringify(shipClothesAux))
    }, [shipClothesAux])

    function goToNextStep() {
        navigate("/ship-my-clothes/choose-delivery")
        console.log(shipClothesAux)
    }



    function changeQstn1(event, selectedValue) {
        const newValue = Number(selectedValue); //colocar numa variavel o novo valor inserido no select
        const selectedValues = event.map(option => Number(option.value)); // Extrair valores do "event"
    
        // Se não houver nenhum valor selecionado, remova todos os valores de shipClothesAux.qstn1
        if (selectedValues.length === 0) {
            setShipClothesAux({ ...shipClothesAux, qstn1: [] });
            return;
        }
    
        // Verificar valores que estão presentes em "shipClothesAux.qstn1", mas não em "selectedValues"
        const valuesToRemove = shipClothesAux.qstn1.filter(value => !selectedValues.includes(value));
    
        if (!shipClothesAux.qstn1.includes(newValue)) {
            const updatedQstn1 = [...shipClothesAux.qstn1, newValue];
            setShipClothesAux({ ...shipClothesAux, qstn1: updatedQstn1 });
        } else {
            // Remover valores de "shipClothesAux" que não estão presentes no "event"
            const updatedQstn1 = shipClothesAux.qstn1.filter(id => !valuesToRemove.includes(id)); 
            setShipClothesAux({ ...shipClothesAux, qstn1: updatedQstn1 });
        }
    }

    // function changeQstn1(e) {
    //     if (e.target.checked) {
    //         shipClothesAux.qstn1.push(Number(e.target.value))
    //         setShipClothesAux({ ...shipClothesAux, qstn1: shipClothesAux.qstn1 })
    //     } else {
    //         shipClothesAux.qstn1 = (shipClothesAux.qstn1).filter(id => id !== Number(e.target.value))
    //         setShipClothesAux({ ...shipClothesAux, qstn1: shipClothesAux.qstn1 })
    //     }
    // }

    function changeQstn3(e) {
        if (e.target.checked) {
            shipClothesAux.qstn3.push(Number(e.target.value))
            setShipClothesAux({ ...shipClothesAux, qstn3: shipClothesAux.qstn3 })
        } else {
            shipClothesAux.qstn3 = (shipClothesAux.qstn3).filter(id => id !== Number(e.target.value))
            setShipClothesAux({ ...shipClothesAux, qstn3: shipClothesAux.qstn3 })
        }
    }

    return (
        <div className='w-full h-full page-footer-buttons'>
            {!loadingQuestions && <div className='w-full h-full flex flex-col justify-between gap-4'>
                <div className='flex flex-col gap-3'>
                    <div className='flex flex-col gap-3'>
                        <div>
                            <h2>{t("ship_my_clothes.our_green_comunity")} <span className='text-primary'>{t("ship_my_clothes.green_comunity")}</span></h2>
                            <br></br>
                            <span>{t("ship_my_clothes.reward")}</span>
                            <br></br><br></br>
                            <div className='flex flex-col'>
                                {questions.qstn2.map(option => (
                                    <div key={"qstn2." + option.id} className='input-radio'>
                                        <input type="radio" className="radio" id={"qstn2" + option.id} name="qstn2" value={option.id} checked={shipClothesAux.qstn2 == option.id} onChange={(e) => setShipClothesAux({ ...shipClothesAux, qstn2: Number(e.target.value) })}></input>
                                        <label htmlFor={"qstn2" + option.id} className="ml-1">{option.description}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='mt-6 w-full border-b border-gray-300'></div>
                    </div>
                    {/* <div className='flex flex-col gap-3 mt-6'>
                        <div>
                            {t("ship_my_clothes.brands_suits_your_style")}
                            <br></br><br></br>
                            <div className='flex flex-col'>
                                {questions.qstn1.map(option => (
                                    <div key={"qstn1." + option.id} className='input-radio'>
                                        <input type="checkbox" className="checkbox" id={"qstn1" + option.id} name="qstn1" value={option.id} checked={(shipClothesAux.qstn1).includes(option.id)} onChange={(e) => changeQstn1(e)}></input>
                                        <label htmlFor={"qstn1" + option.id} className="ml-1">{option.description}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='mt-6 w-full border-b border-gray-300'></div>
                    </div> */}

                    <div className='flex flex-col gap-3 mt-6'>
                        {t("ship_my_clothes.brands_suits_your_style")}
                        <br></br><br></br>
                        <Select 
                            options={options}
                            //Coloca os valore pre-selecionados com base na localStorage
                            value={options.filter(option => shipClothesAux.qstn1.includes(Number(option.value)))}
                            onChange={(e) => {
                                //confirma se o evento do select tem conteudo, caso nao tenha envia um array vazio para ser removido a informação do "shipClothesAux"
                                if (e && e.length > 0) {
                                    const newValue = e[e.length - 1].value;
                                    changeQstn1(e, newValue);
                                } else {
                                    changeQstn1([], null); // Passar um array vazio e um valor nulo para evitar problemas
                                }
                            }}
                            isMulti={true}
                            placeholder="Seleciona as marcas"
                            touchUi={true}
                            isSearchable={ false }
                        />
                        <div className='mt-6 w-full border-b border-gray-300'></div>
                    </div>
                    <div className='flex flex-col gap-3 mt-6'>
                        <div>
                            {t("ship_my_clothes.sell_donate_clothes")}
                            <br></br><br></br>
                            <div className='flex flex-col'>
                                {questions.qstn3.map(option => (
                                    <div key={"qstn3." + option.id} className='input-radio flex'>
                                        <input type="checkbox" className="checkbox" id={"qstn3" + option.id} name="qstn3" value={option.id} checked={(shipClothesAux.qstn3).includes(option.id)} onChange={(e) => changeQstn3(e)}></input>
                                        <label htmlFor={"qstn3" + option.id} className="ml-1">{option.description}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='pb-3 w-full flex justify-end'>
                    <button onClick={goToNextStep} disabled={shipClothesAux.qstn1.length == 0 || shipClothesAux.qstn2 == null || shipClothesAux.qstn3 == null} className='px-4 w-auto button primary-button flex gap-2 items-center justify-center'>{t("geral.next")} <GrLinkNext /></button>
                </div>
            </div>}
        </div>
    );
}