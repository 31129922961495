
export default function PrivacyPolicy() {
    return (
        <div className='w-full h-full page-footer-buttons'>
            <div className='w-full h-full flex flex-col gap-3'>
                <h2>Política de privacidade</h2>
                <div>
                    <section>
                        <ol start="1" class="mt-5 div-medium px-6 list-decimal">
                            <li>
                                <div>
                                    <div>Sobre esta Política de Privacidade</div>
                                </div>
                            </li>
                        </ol>
                        <p class="mt-2">
                            <div>
                                <div>Na SWYCO estamos comprometidos em proteger sua privacidade. Esta
                                    Política de Privacidade aplica-se às informações e dados recolhidos em </div><a
                                        aria-label="link swyco" href="http://www.swyco.io">
                                    <div>www.swyco.io</div>
                                </a>
                                <div> , e inclui informações pessoais fornecidas por você ao preencher
                                    qualquer um dos formulários que podem ser encontrados ao longo do site.</div>
                            </div> <a aria-label="link swyco" href="http://www.swyco.io">
                                <div></div>
                            </a>
                            <div></div>
                        </p>
                        <p class="mt-2">
                            <div>
                                <div>Nesta Política de Privacidade poderá encontrar informação
                                    relativa à recolha e utilização de dados pessoais para diversos fins como informação de vendas,
                                    comunicação, melhoria de experiência e efeitos de recrutamento.</div>
                            </div>
                        </p>
                        <p class="mt-2">
                            <div>
                                <div>Se você não concorda com as práticas de dados descritas nesta
                                    Política de Privacidade, você não deve usar o site da SWYCO.</div>
                            </div>
                        </p>
                        <p class="mt-2">
                            <div>
                                <div>Se você está se candidatando a uma vaga na SWYCO e deseja ler
                                    mais sobre a forma como coletamos e utilizamos os dados que você nos fornece ao longo do processo de
                                    recrutamento, recomendamos que leia todos os pontos desta Política de Privacidade antes de pular
                                    direto para o tópico 12 .</div>
                            </div>
                        </p>
                        <p class="mt-2">
                            <div>
                                <div>Para efeitos da presente política, entende-se por “dados
                                    pessoais” ou “dados” qualquer informação, de qualquer natureza e qualquer que seja a sua forma,
                                    relativa a uma pessoa singular identificada ou identificável. É considerada pessoa singular
                                    identificável aquela que possa ser identificada, direta ou indiretamente, nomeadamente por
                                    referência a um nome, a um número de identificação/número de contribuinte, a dados de localização, a
                                    um identificador online ou a outros elementos específicos relacionados com a condição física,
                                    fisiológica, genética ou identidade económica dessa pessoa singular.</div>
                            </div>
                        </p>
                    </section>
                    <section>
                        <ol start="2" class="mt-5 div-medium px-6 list-decimal">
                            <li>
                                <div>
                                    <div>Retenção de Dados Pessoais</div>
                                </div>
                            </li>
                        </ol>
                        <p class="mt-2">
                            <div>
                                <div>A SWYCO trata os seus dados pessoais durante o tempo necessário
                                    para a finalidade para a qual foram recolhidos.</div>
                            </div>
                        </p>
                        <p class="mt-2">
                            <div>
                                <div>No entanto, a SWYCO poderá armazená-los por um período superior,
                                    sempre que:</div>
                            </div>
                        </p>
                        <ol class="mt-2 px-8 list-[lower-alpha]">
                            <li>
                                <div>
                                    <div>Existe um prazo determinado pela lei;</div>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <div>Existe um período de tempo recomendado por qualquer
                                        autoridade de supervisão competente; ou</div>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <div>São necessários para a declaração, o exercício ou a defesa de
                                        um direito em processo judicial.</div>
                                </div>
                            </li>
                        </ol>
                        <p class="mt-2">
                            <div>
                                <div>Por exemplo, a SWYCO conservará os dados para cumprimento de
                                    obrigações legais contabilísticas ou fiscais durante um período de 10 anos. Assim que decorrido o
                                    prazo máximo de conservação, os seus dados pessoais serão anonimizados de forma irreversível (os
                                    dados anonimizados poderão ser conservados) ou serão destruídos de forma segura.</div>
                            </div>
                        </p>
                    </section>
                    <section>
                        <ol start="3" class="mt-5 div-medium px-6 list-decimal">
                            <li>
                                <div>
                                    <div>Saiba mais sobre seus direitos</div>
                                </div>
                            </li>
                        </ol>
                        <p class="mt-2">
                            <div>
                                <div>Você deve estar ciente de que o Regulamento Geral de Proteção de
                                    Dados (GDPR) confere certos direitos aos indivíduos em relação às suas informações pessoais. Isto
                                    inclui o direito de nos solicitar uma cópia dos seus dados pessoais coletados através do uso de
                                </div><a aria-label="link swyco" href="http://www.swyco.io">
                                    <div>www.swyco.io</div>
                                </a>
                                <div> ; solicitar-nos a retificação ou atualização dos seus dados
                                    pessoais e solicitar-nos que apaguemos os seus dados pessoais ou deixemos de os utilizar para
                                    determinados fins. Assim, poderá, a qualquer momento, exercer os seguintes direitos:</div>
                            </div> <a aria-label="link swyco" href="http://www.swyco.io">
                                <div></div>
                            </a>
                            <div></div>
                        </p>
                        <ul class="mt-2 px-8 list-[upper-alpha]">
                            <li><span class="div-medium">
                                <div>
                                    <div>Direito de acesso </div>
                                </div>
                                <div>
                                    <div>–</div>
                                </div>
                            </span>
                                <div>
                                    <div> direito de obter confirmação sobre quais dos seus dados
                                        pessoais são tratados e informação sobre os mesmos; por exemplo, quais as finalidades do
                                        tratamento e quais os prazos de conservação, entre outros;</div>
                                </div>
                            </li>
                            <li><span class="div-medium">
                                <div>
                                    <div>Direito à retificação </div>
                                </div>
                                <div>
                                    <div>–</div>
                                </div>
                            </span>
                                <div>
                                    <div> direito de obter a retificação dos seus dados pessoais que
                                        estejam inexatos ou incompletos;</div>
                                </div>
                            </li>
                            <li><span class="div-medium">
                                <div>
                                    <div>Direito ao apagamento ou 'direito a ser esquecido'
                                    </div>
                                </div>
                                <div>
                                    <div>–</div>
                                </div>
                            </span>
                                <div>
                                    <div> direito de obter o apagamento dos seus dados pessoais, a
                                        menos que existam motivos válidos para armazenar os seus dados. Por exemplo, a SWYCO pode ser
                                        obrigada a conservar os dados para cumprimento de uma obrigação legal ou porque estão em curso
                                        processos judiciais;</div>
                                </div>
                            </li>
                            <li><span class="div-medium">
                                <div>
                                    <div>Direito à portabilidade dos dados </div>
                                </div>
                                <div>
                                    <div>–</div>
                                </div>
                            </span>
                                <div>
                                    <div> direito de receber os dados que forneceu num formato digital
                                        comummente utilizado e legível por máquina, bem como o direito de transmitir diretamente esses
                                        dados, se for tecnicamente possível;</div>
                                </div>
                            </li>
                            <li><span class="div-medium">
                                <div>
                                    <div>Direito de oposição </div>
                                </div>
                                <div>
                                    <div>–</div>
                                </div>
                            </span>
                                <div>
                                    <div> direito de oposição ao tratamento dos seus dados pessoais
                                        com base num interesse legítimo, salvo se motivos legítimos imperiosos para o tratamento
                                        prevaleçam sobre os seus interesses, direitos e liberdades, ou para a defesa de um direito
                                        durante um processo judicial;</div>
                                </div>
                            </li>
                            <li><span class="div-medium">
                                <div>
                                    <div>Direito à restrição do tratamento </div>
                                </div>
                                <div>
                                    <div>–</div>
                                </div>
                            </span>
                                <div>
                                    <div> direito de obter a restrição do tratamento dos seus dados
                                        pessoais, sob a forma de: (i) suspensão do tratamento, ou (ii) limitação do âmbito do tratamento
                                        a determinadas categorias de dados ou finalidades de tratamento;</div>
                                </div>
                            </li>
                            <li><span class="div-medium">
                                <div>
                                    <div>Direito de retirar o consentimento </div>
                                </div>
                                <div>
                                    <div>–</div>
                                </div>
                            </span>
                                <div>
                                    <div> se o tratamento dos seus dados pessoais estiver sujeito à
                                        obtenção do seu consentimento, tem o direito de retirá-lo, sem que tal retirada do consentimento
                                        afete a licitude do tratamento baseado no consentimento antes da sua retirada.</div>
                                </div>
                            </li>
                        </ul>
                        <p class="mt-2">
                            <div>
                                <div>Se você quiser exercer algum dos seus direitos ou tiver alguma
                                    dúvida sobre sua privacidade, seus direitos ou como exercê-los, entre em contato conosco pelo e-mail
                                </div><a aria-label="e-mail swyco" href="mailto:info@swyco.io">
                                    <div>info@swyco.io</div>
                                </a>
                                <div> . </div>
                                <div>Responderemos ao seu pedido dentro dos prazos legais.</div>
                            </div> <a aria-label="e-mail swyco" href="mailto:info@swyco.io">
                                <div></div>
                            </a>
                            <div></div>
                            <div></div>
                        </p>
                        <p class="mt-2">
                            <div>
                                <div>Embora esperemos poder responder a quaisquer perguntas que possa
                                    ter, se tiver preocupações não resolvidas, o RGPD também lhe dá o direito de apresentar uma
                                    reclamação junto de uma autoridade de supervisão, em particular no estado da União Europeia (ou
                                    Espaço Económico Europeu) onde trabalha. , normalmente ao vivo ou onde ocorreu qualquer suposta
                                    violação das leis de proteção de dados. A autoridade de controlo em Portugal é a Autoridade
                                    Portuguesa de Proteção de Dados (CNPD, </div><a aria-label="link cnpd" href="http://www.cnpd.pt">
                                    <div>www.cnpd.pt</div>
                                </a>
                                <div> ) </div>
                                <div>, que pode ser contactada através do telefone: </div>
                                <div>+351 21 392 84 00 </div>
                                <div>ou </div><a aria-label="e-mail cnpd"
                                    href="mailto:geral@cnpd.pt">
                                    <div>geral@cnpd.pt</div>
                                </a>
                                <div> .</div>
                            </div> <a aria-label="link cnpd" href="http://www.cnpd.pt">
                                <div></div>
                            </a>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div> <a aria-label="e-mail cnpd" href="mailto:geral@cnpd.pt">
                                <div></div>
                            </a>
                            <div></div>
                        </p>
                        <p class="mt-5 underline">
                            <div>
                                <div>Que dados pessoais recolhemos e como os recolhemos?</div>
                            </div>
                        </p>
                        <p class="mt-2">
                            <div>
                                <div>Ao usar </div><a aria-label="link swyco"
                                    href="http://www.swyco.io">
                                    <div>www.swyco.io</div>
                                </a>
                                <div> , as informações que coletamos e processamos incluem, mas não
                                    estão limitadas às seguintes categorias:</div>
                            </div> <a aria-label="link swyco" href="http://www.swyco.io">
                                <div></div>
                            </a>
                            <div></div>
                        </p>
                        <ol class="mt-2 px-8 list-decimal">
                            <li><span class="div-medium">
                                <div>
                                    <div>Informações de Contato:</div>
                                </div>
                            </span>
                                <div>
                                    <div> Seu nome e sobrenome, endereço de e-mail, telefone, cidade e
                                        país de residência e quaisquer outras informações fornecidas por você nas caixas de mensagens
                                        dos formulários, chat do site ou via e-mail;</div>
                                </div>
                            </li>
                            <li><span class="div-medium">
                                <div>
                                    <div>Informações da Marca/Empresa:</div>
                                </div>
                            </span>
                                <div>
                                    <div> O nome da sua marca ou empresa, o seu número de telefone, a
                                        quantidade de itens enviados por temporada e qualquer outra informação fornecida por você nas
                                        caixas de mensagens ou chat do site;</div>
                                </div>
                            </li>
                        </ol>
                    </section>
                    <section>
                        <ol start="4" class="mt-5 div-medium px-6 list-decimal">
                            <li>
                                <div>
                                    <div>Para que utilizamos os seus dados pessoais?</div>
                                </div>
                            </li>
                        </ol>
                        <p class="mt-5 underline">
                            <div>
                                <div>Uso de informações pessoais</div>
                            </div>
                        </p>
                        <p class="mt-2">
                            <div>
                                <div>Usamos suas informações para diversos fins, como:</div>
                            </div>
                        </p>
                        <ul class="mt-2 mx-8 list-disc">
                            <li><span class="div-medium">
                                <div>
                                    <div>Comunicação:</div>
                                </div>
                            </span>
                                <div>
                                    <div> Recolhemos os dados pessoais que nos fornece, como o seu
                                        nome e apelido, e-mail e as informações sobre a sua marca (se aplicável) com a finalidade de
                                        comunicar consigo no futuro, bem como para efeitos de vendas ( se aplicável). Só será contactado
                                        por nós com o seu consentimento para o fazer.</div>
                                </div>
                            </li>
                            <li><span class="div-medium">
                                <div>
                                    <div>Experiência do usuário do site:</div>
                                </div>
                            </span>
                                <div>
                                    <div> Processamos suas informações (em nível agregado) para
                                        realizar análises a fim de compreender, manter e melhorar sua experiência em </div><a
                                            aria-label="link swyco" href="http://www.swyco.io">
                                        <div>www.swyco.io</div>
                                    </a>
                                    <div> e com SWYCO.</div>
                                </div> <a aria-label="link swyco" href="http://www.swyco.io">
                                    <div></div>
                                </a>
                                <div></div>
                            </li>
                        </ul>
                        <p class="mt-5 underline">
                            <div>
                                <div>Nunca venderemos ou compartilharemos suas informações pessoais
                                    com terceiros.</div>
                            </div>
                        </p>
                    </section>
                    <section>
                        <ol start="5" class="mt-5 div-medium px-8 list-decimal">
                            <li>
                                <div>
                                    <div>Base Legal para Processamento</div>
                                </div>
                            </li>
                        </ol>
                        <p class="mt-2">
                            <div>
                                <div>A SWYCO poderá tratar os seus dados pessoais caso se aplique um
                                    dos seguintes fundamentos de licitude:</div>
                            </div>
                        </p>
                        <ol class="mt-2 px-8 list-decimal">
                            <li><span class="div-medium">
                                <div>
                                    <div>Consentimento dos titulares dos dados:</div>
                                </div>
                            </span>
                                <div>
                                    <div> o consentimento será dado por um ato afirmativo claro que
                                        estabeleça uma indicação dada de forma livre, específica, informada e inequívoca, através de uma
                                        declaração (por escrito ou oralmente) ou por um ato positivo inequívoco (através do
                                        preenchimento de uma opção). O consentimento pode ser retirado a qualquer momento, sem que tal
                                        retirada afete o tratamento efetuado enquanto tal consentimento esteve em vigor; Você tem o
                                        direito de retirar tal consentimento a qualquer momento, entrando em contato da forma descrita
                                        nesta Política de Privacidade;</div>
                                </div>
                            </li>
                            <li><span class="div-medium">
                                <div>
                                    <div>Execução de contrato ou diligências pré-contratuais:
                                    </div>
                                </div>
                            </span>
                                <div>
                                    <div> sempre que o tratamento de dados pessoais seja efetuado para
                                        efeitos de manutenção da relação entre a SWYCO e o titular dos dados, ou para a realização de
                                        diligências pré-contratuais a seu pedido, tais como gestão de contactos/reclamações ,
                                        gerenciamento de cobrança/pagamento;</div>
                                </div>
                            </li>
                            <li><span class="div-medium">
                                <div>
                                    <div>Cumprimento de uma obrigação legal:</div>
                                </div>
                            </span>
                                <div>
                                    <div> sempre que o tratamento seja necessário para cumprir as
                                        obrigações legais a que a SWYCO está sujeita, como a comunicação a órgãos administrativos,
                                        judiciais e/ou responsáveis ​​pela aplicação da lei;</div>
                                </div>
                            </li>
                            <li><span class="div-medium">
                                <div>
                                    <div>No contexto de um interesse legítimo:</div>
                                </div>
                            </span>
                                <div>
                                    <div> sempre que seja necessário tratar dados pessoais para
                                        salvaguardar os interesses legítimos da SWYCO ou de terceiros. Os interesses legítimos da SWYCO
                                        incluem, mas não estão limitados a, (i) o exercício de direitos legais e de defesa em caso de
                                        disputas legais; (ii) a manutenção da segurança, rede, infraestruturas e sistemas tecnológicos
                                        da SWYCO (incluindo controlos de acesso), bem como a sua gestão informática, ou (iii) a
                                        implementação de um acordo legal.</div>
                                </div>
                            </li>
                        </ol>
                        <p class="mt-2">
                            <div>
                                <div>Usamos as informações que coletamos apenas em conformidade com
                                    esta Política de Privacidade. Se você usar </div><a aria-label="link swyco"
                                        href="http://www.swyco.io">
                                    <div>www.swyco.io,</div>
                                </a>
                                <div> você é obrigado, por meio de nossos acordos com você, a cumprir
                                    esta Política de Privacidade.</div>
                            </div> <a aria-label="link swyco" href="http://www.swyco.io">
                                <div></div>
                            </a>
                            <div></div>
                        </p>
                    </section>
                    <section>
                        <ol start="6" class="mt-5 div-medium px-6 list-decimal">
                            <li>
                                <div>
                                    <div>Como controlar e alterar suas informações pessoais</div>
                                </div>
                            </li>
                        </ol>
                        <p class="mt-2">
                            <div>
                                <div>Você pode solicitar acesso, correção, atualização ou exclusão de
                                    suas informações pessoais. Se tivermos coletado e processado suas informações pessoais com o seu
                                    consentimento, você poderá retirar seu consentimento a qualquer momento. Para fazer isso, envie-nos
                                    uma mensagem para </div><a aria-label="e-mail swyco" href="mailto:info@swyco.io">
                                    <div>info@swyco.io</div>
                                </a>
                                <div> .</div>
                            </div> <a aria-label="e-mail swyco" href="mailto:info@swyco.io">
                                <div></div>
                            </a>
                            <div></div>
                        </p>
                        <p class="mt-2">
                            <div>
                                <div>Caso não cumpramos a nossa Política de Privacidade, ou se
                                    considerar que os seus direitos foram violados, tem o direito de reclamar junto de uma autoridade de
                                    proteção de dados sobre a recolha e utilização dos seus dados pessoais.</div>
                            </div>
                        </p>
                    </section>
                    <section>
                        <ol start="7" class="mt-5 div-medium px-6 list-decimal">
                            <li>
                                <div>
                                    <div>Mantendo seus dados pessoais seguros</div>
                                </div>
                            </li>
                        </ol>
                        <p class="mt-2">
                            <div>
                                <div>Podemos atualizar esta Política de Privacidade; você receberá
                                    quaisquer alterações possíveis por e-mail. Embora iremos notificá-lo sobre quaisquer alterações
                                    materiais nesta Política de Privacidade antes que as alterações entrem em vigor, encorajamos você a
                                    revisar esta Política de Privacidade periodicamente.</div>
                            </div>
                        </p>
                        <p>
                            <div>
                                <div>No entanto, observe que nenhum sistema é completamente seguro.
                                    Embora façamos o nosso melhor para proteger os seus dados pessoais, não podemos garantir a segurança
                                    dos seus dados transmitidos através de qualquer meio online, pelo que qualquer transmissão permanece
                                    por sua conta e risco.</div>
                            </div>
                        </p>
                    </section>
                    <section>
                        <ol start="8" class="mt-5 div-medium px-6 list-decimal">
                            <li>
                                <div>
                                    <div>Alterações nesta Política de Privacidade</div>
                                </div>
                            </li>
                        </ol>
                        <p class="mt-2">
                            <div>
                                <div>Podemos atualizar esta Política de Privacidade; você receberá
                                    quaisquer alterações possíveis por e-mail. Embora iremos notificá-lo sobre quaisquer alterações
                                    materiais nesta Política de Privacidade antes que as alterações entrem em vigor, encorajamos você a
                                    revisar esta Política de Privacidade periodicamente.</div>
                            </div>
                        </p>
                    </section>
                    <section>
                        <ol start="9" class="mt-5 div-medium px-6 list-decimal">
                            <li>
                                <div>
                                    <div>Nota de privacidade para recrutamento:</div>
                                </div>
                            </li>
                        </ol>
                        <section>
                            <ol start="1" class="mt-5 div-medium px-8 list-decimal">
                                <li>
                                    <div>
                                        <div>Introdução</div>
                                    </div>
                                </li>
                            </ol>
                            <p class="mt-2">
                                <div>
                                    <div>Obrigado por considerar uma oportunidade de emprego na SWYCO.
                                        Para se candidatar a um emprego, você precisa nos fornecer algumas informações pessoais sobre
                                        você.</div>
                                </div>
                            </p>
                            <p class="mt-2">
                                <div>
                                    <div>Quando você se candidatar a uma vaga de emprego publicada por
                                        nós, estas disposições do Aviso de Privacidade serão aplicadas ao nosso processamento de suas
                                        informações pessoais, além dos demais tópicos desta Política de Privacidade.</div>
                                </div>
                            </p>
                            <p class="mt-2">
                                <div>
                                    <div>O objetivo desta nota de privacidade para recrutamento é
                                        explicar como recolhemos e tratamos os seus dados pessoais ao longo do processo de contratação e
                                        fornecer-lhe informações sobre os direitos que tem em relação aos seus dados pessoais.</div>
                                </div>
                            </p>
                            <p class="mt-2">
                                <div>
                                    <div>Usamos a plataforma Recruitee para auxiliar em nosso processo
                                        de recrutamento. O Recruitee só tem o direito de processar os seus dados pessoais de acordo com
                                        as nossas instruções. Para saber mais sobre a forma como o Recruitee processa os seus dados,
                                        leia a Política de Privacidade do Recruitee aqui: </div><a aria-label="recrutador swyco"
                                            href="https://recruitee.com/privacy">
                                        <div>https://recruitee.com/privacy</div>
                                    </a>
                                </div> <a aria-label="recrutador swyco" href="https://recruitee.com/privacy">
                                    <div></div>
                                </a>
                            </p>
                            <p class="mt-2">
                                <div>
                                    <div>Quando você se candidata a uma vaga de emprego por meio da
                                        função de candidatura em um site de empregos ou provedor de serviços on-line semelhante
                                        (“parceiro”), você deve observar que o parceiro relevante pode reter seus dados pessoais e
                                        também pode coletar dados nossos em relação ao progresso da sua aplicação. Qualquer utilização
                                        dos seus dados pelo parceiro estará de acordo com o Aviso de Privacidade do parceiro.</div>
                                </div>
                            </p>
                        </section>
                        <section>
                            <ol start="2" class="mt-5 div-medium px-8 list-decimal">
                                <li>
                                    <div>
                                        <div>Quais informações pessoais coletamos de você e como o
                                            fazemos?</div>
                                    </div>
                                </li>
                            </ol>
                            <p class="mt-2">
                                <div>
                                    <div>Quando você se candidata a um emprego na SWYCO, os tipos de
                                        informações que coletamos e processamos incluem, entre outras, as seguintes categorias:</div>
                                </div>
                            </p>
                            <ol class="mt-2 px-8 list-[lower-roman]">
                                <li>
                                    <div>
                                        <div>Informações de contato e demográficas: Seu nome e
                                            sobrenome, endereço de e-mail, endereço postal, número de telefone e qualquer outra
                                            informação fornecida por você;</div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <div>Detalhes básicos e informações de referência: Seu
                                            currículo ou currículo, carta de apresentação, certificados e qualificações acadêmicas,
                                            experiência profissional, histórico escolar e similares (se aplicável);</div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <div>Informações sobre emprego: O nome do cargo ao qual se
                                            candidata e informações sobre o tipo de emprego que procura ou no qual pode ter interesse;
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <div>Informações publicamente disponíveis: Informações de
                                            perfil, habilitações académicas e experiência profissional provenientes de dives públicas e
                                            que sejam relevantes para o processo de contratação, por exemplo, o seu perfil no LinkedIn.
                                        </div>
                                    </div>
                                </li>
                            </ol>
                        </section>
                        <section>
                            <ol start="3" class="mt-5 div-medium px-8 list-decimal">
                                <li>
                                    <div>
                                        <div>Quais informações pessoais coletamos de você e como o
                                            fazemos?</div>
                                    </div>
                                </li>
                            </ol>
                            <ol class="mt-2 px-8 list-[lower-roman]">
                                <li>
                                    <div>
                                        <div>Para comunicar consigo durante todo o processo de
                                            recrutamento;</div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <div>Atualizar e manter atualizados os seus dados de contato,
                                            currículo e outras informações caso você nos forneça uma versão nova ou atualizada dos
                                            mesmos;</div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <div>Avaliar as suas qualificações, competências e aptidão;
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <div>Para considerá-lo para outras oportunidades de emprego e
                                            informá-lo sobre elas (com o seu consentimento, se exigido pelas leis aplicáveis);</div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <div>Para verificar suas informações e realizar verificações
                                            de antecedentes e referências;</div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <div>Para preparar o seu contrato de trabalho caso lhe seja
                                            oferecido um emprego na SWYCO.</div>
                                    </div>
                                </li>
                            </ol>
                        </section>
                        <section>
                            <ol start="4" class="mt-5 div-medium px-8 list-decimal">
                                <li>
                                    <div>
                                        <div>Como controlar e alterar suas informações pessoais?
                                        </div>
                                    </div>
                                </li>
                            </ol>
                            <p class="mt-2">
                                <div>
                                    <div>Para saber mais sobre a forma como você pode controlar e
                                        alterar suas informações pessoais, leia o tópico 7 desta Política de Privacidade.</div>
                                </div>
                            </p>
                        </section>
                        <section>
                            <ol start="5" class="mt-5 div-medium px-8 list-decimal">
                                <li>
                                    <div>
                                        <div>Divulgação de suas informações</div>
                                    </div>
                                </li>
                            </ol>
                            <p class="mt-2">
                                <div>
                                    <div>Passamos suas informações ao nosso provedor de serviços de
                                        recrutamento terceirizado, Recruitee, que as utiliza apenas de acordo com nossas instruções e
                                        conforme exigido por lei.</div>
                                </div>
                            </p>
                        </section>
                        <section>
                            <ol start="6" class="mt-5 div-medium px-8 list-decimal">
                                <li>
                                    <div>
                                        <div>Mantendo seus dados pessoais seguros</div>
                                    </div>
                                </li>
                            </ol>
                            <p class="mt-2">
                                <div>
                                    <div>Para saber mais sobre a forma como garantimos a segurança dos
                                        seus dados, leia o tópico 6 desta Política de Privacidade.</div>
                                </div>
                            </p>
                        </section>
                        <section>
                            <ol start="7" class="mt-5 div-medium px-8 list-decimal">
                                <li>
                                    <div>
                                        <div>Onde armazenamos os seus dados pessoais</div>
                                    </div>
                                </li>
                            </ol>
                            <p class="mt-2">
                                <div>
                                    <div>Para saber mais sobre onde armazenamos seus dados pessoais,
                                        leia o tópico 7 desta Política de Privacidade.</div>
                                </div>
                            </p>
                        </section>
                        <section>
                            <ol start="8" class="mt-5 div-medium px-8 list-decimal">
                                <li>
                                    <div>
                                        <div>Durante quanto tempo conservamos os seus dados pessoais
                                        </div>
                                    </div>
                                </li>
                            </ol>
                            <p class="mt-2">
                                <div>
                                    <div>Conservaremos os dados que recolhemos sobre si ao longo do
                                        processo de recrutamento por um período de 5 anos, para cumprimento de obrigações legais. Também
                                        poderemos reter as suas informações para fins de recrutamento futuro por um período de 2 anos,
                                        se você consentir.</div>
                                </div>
                            </p>
                        </section>
                        <section>
                            <ol start="9" class="mt-5 div-medium px-8 list-decimal">
                                <li>
                                    <div>
                                        <div>Alterações nesta Política de Privacidade</div>
                                    </div>
                                </li>
                            </ol>
                            <p class="mt-2">
                                <div>
                                    <div>Para saber mais sobre a forma como as alterações nesta
                                        Política de Privacidade serão tratadas e comunicadas a você, leia o tópico 9 desta Política de
                                        Privacidade.</div>
                                </div>
                            </p>
                        </section>
                        <section>
                            <ol start="10" class="mt-5 div-medium px-8 list-decimal">
                                <li>
                                    <div>
                                        <div>Controlador de Dados – Como entrar em contato conosco?
                                        </div>
                                    </div>
                                </li>
                            </ol>
                            <p class="mt-2">
                                <div>
                                    <div>Obrigado por ler nossa Política de Privacidade.</div>
                                </div>
                            </p>
                            <p class="mt-2"><span class="div-medium">
                                <div>
                                    <div>A Logical Benefit, Lda</div>
                                </div>
                            </span>
                                <div>
                                    <div> atua como responsável pelo tratamento dos dados pessoais dos
                                        visitantes e utilizadores do Site.</div>
                                </div>
                            </p>
                            <p class="mt-2">
                                <div>
                                    <div>Se tiver alguma questão relacionada com o tratamento dos seus
                                        Dados Pessoais, por favor contacte-nos enviando um email para </div><a
                                            aria-label="e-mail swyco" href="mailto:info@swyco.io">
                                        <div>info@swyco.io</div>
                                    </a>
                                    <div> ou enviando-nos uma carta para </div>
                                    <div>Zona Industrial da Várzea, Rua do Parque Industrial, Lote 8
                                        4755-539 Barcelos</div>
                                </div> <a aria-label="e-mail swyco" href="mailto:info@swyco.io">
                                    <div></div>
                                </a>
                                <div></div>
                                <div></div>
                            </p>
                        </section>
                    </section>
                </div>
            </div>
        </div>
    )
}