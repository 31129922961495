import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';


i18n
    // .use(LanguageDetector)
    .use(initReactI18next) // bind react-i18next to the instance
    .init({
        fallbackLng: 'en',
        debug: true,
        interpolation: {
            escapeValue: false, // not needed for react!!
        },
        resources: {
            en: {
                translation: {
                    geral: {
                        log_out: "Log out",
                        edit: "Edit",
                        delete: "Delete",
                        optional: "optional",
                        cancel: "Cancel",
                        default: "Default",
                        save: "Save",
                        next: "Next",
                    },
                    errors: {
                        required_fields: "Required fields",
                        invalid_credentials: "Invalid credentials",
                        pwd_dont_match: "Password doesn't match.",
                        pwd_rules: "Minimum 8 characters, including lowercase, uppercase, a number and a special character.",
                        error_email: "Invalid email.",
                        error_password: "Invalid password."
                    },
                    pages: {
                        home: "Home",
                        login_account: "Login",
                        my_account: "My account",
                        my_activity: "My activity",
                        my_address_book: "My address book",
                        new_address: "New address",
                        edit_address: "Edit address",
                        change_password: "Change password",
                        ship_my_clothes: "Ship my clothes",
                        choose_delivery: "Choose delivery",
                        delivery: "Delivery details",
                        summary: "Summary",
                        me: "Me",
                        settings: "Settings",
                        ship_my_clothes_small: "Ship my clothes",
                        privacy_policy: "Privacy Policy",
                        terms_and_conditions: "Terms & conditions"
                    },
                    home: {
                        how_it_works: "How it works",
                        how_it_works_text: "Help conserve natural resources, reduce greenhouse gas emissions and minimize textile waste.",
                        how_it_works_1: "Fill in the data",
                        how_it_works_1_text: "Complete a simple form to provide details about the items you would like to recycle. You will then receive a shipping label to send your items.",
                        how_it_works_2: "Send your pieces",
                        how_it_works_2_text: "Pack your items safely and leave the order at one of our delivery locations.",
                        how_it_works_3: "We take care of the rest",
                        how_it_works_3_text: "When eligible items sell, you receive shopping credit or discount codes to use at select stores.",
                    },
                    login: {
                        login_text: "Give your clothing",
                        login_text_cont: "a second chance!",
                        login: "Login",
                        sign_up: "Sign up",
                        or_login_with: "or login with...",
                        or_register_with: "or register with...",
                        dont_have_account: "I don't have an account.",
                        already_have_account: "I already have an account.",
                        terms_conditions: "I accept the terms and conditions.",
                        first_name: "First name",
                        last_name: "Last name",
                        example_email: "example@swyco.io",
                        error_login: "Invalid authentication. Incorrect email/password.",
                    },
                    my_account: {
                        hi: "Hi",
                        address_book: "My address book",
                        change_pwd: "Change password",
                        my_activity: {
                            welcome: "Welcome",
                            my_activity: "My activity",
                            no_activity: "You currently have no activity.",
                            start_activity: "Ship my clothes"
                        },
                        my_address_book: {
                            address: "Address",
                            new_address: "New address",
                            edit_address: "Edit address",
                            first_name: "First name",
                            last_name: "Last name",
                            calling_code: "Calling code",
                            phone_number: "Phone number",
                            zip_code: "ZIP code",
                            city: "City",
                            country: "Country",
                            default_address: "Use this address as default.",
                            add_address: "Add new address",
                        },
                        change_password: {
                            password: "Password",
                            new_password: "New password",
                            confirm_password: "Confirm password",
                        }
                    },
                    ship_my_clothes: {
                        our_green_comunity: "Thank you for being part of our",
                        green_comunity: "green community!",
                        reward: "We are trying to reward our members with exclusive content! What types of rewards are you most interested in?",
                        brands_suits_your_style: "Which fashion brands suits your style?",
                        sell_donate_clothes: "How do you normally sell or donate your clothes?",
                        delivery_address: "Delivery address",
                        choose_address: "Choose address",
                        delivery_details: "Delivery details",
                        schedule: "Schedule",
                        pickup_point: "Pickup point",
                        pointers_near_me: "Pointers near me",
                        change_location: "Change location",
                        summary: "Summary",
                        submit: "Submit",
                        success_submission: "Thanks for your submission!",
                        receive_email_submission: "You’ll receive an email with your",
                        receive_email_submission_cont: "pre-paid shipping label.",
                        my_account: "My account",
                        zipcode: "ZIP Code",
                        country: "Country",
                        error_zipcode: "The ZIP Code you entered is invalid."
                    },
                    legal_pages: {
                        how_it_works: "How it works?",
                        privacy_policy: "Privacy policy",
                        terms_conditions: "Terms & conditions"
                    }
                }
            },
            pt: {
                translation: {
                    geral: {
                        log_out: "Terminar sessão",
                        edit: "Editar",
                        delete: "Apagar",
                        optional: "opcional",
                        cancel: "Cancelar",
                        default: "Predefinido",
                        save: "Guardar",
                        next: "Seguinte",
                    },
                    errors: {
                        required_fields: "Campos obrigatórios.",
                        invalid_credentials: "Os dados introduzidos estão incorretos.",
                        pwd_dont_match: "Senhas não coincidem.",
                        pwd_rules: "Mínimo de 8 carateres, incluindo letras minúsculas, maiúsculas, um número e um carater especial.",
                        error_email: "Email inválido.",
                        error_password: "Password inválida."
                    },
                    pages: {
                        home: "Início",
                        login_account: "Iniciar sessão",
                        my_account: "A minha conta",
                        my_activity: "A minha atividade",
                        my_address_book: "Os meus endereços",
                        new_address: "Adicionar endereço",
                        edit_address: "Editar endereço",
                        change_password: "Alterar senha",
                        ship_my_clothes: "Enviar as minhas roupas",
                        choose_delivery: "Endereço de entrega",
                        delivery: "Detalhes da entrega",
                        summary: "Sumário",
                        me: "Atividade",
                        settings: "Definições",
                        ship_my_clothes_small: "Enviar roupas",
                        privacy_policy: "Política de privacidade",
                        terms_and_conditions: "Termos e condições"
                    },
                    home: {
                        how_it_works: "Como funciona?",
                        how_it_works_text: "Ajuda a conservar os recursos naturais, reduzir as emissões de gases com efeito de estufa e minimizar os resíduos têxteis.",
                        how_it_works_1: "Preenche os dados",
                        how_it_works_1_text: "Preenche um formulário simples para fornecer detalhes sobre os itens que gostarias de reciclar. De seguida, receberás uma etiqueta de envio para nos fazeres chegar os teus itens.",
                        how_it_works_2: "Envia as tuas peças",
                        how_it_works_2_text: "Embala os teus itens com segurança e deixa a encomenda num dos nossos locais de entrega, à tua escolha.",
                        how_it_works_3: "Nós tratamos do resto",
                        how_it_works_3_text: "Quando os itens qualificados forem vendidos, receberás crédito de compras ou códigos de desconto para usar em lojas selecionadas.",
                    },
                    login: {
                        login_text: "Dá às tuas roupas",
                        login_text_cont: "uma segunda oportunidade!",
                        login: "Iniciar sessão",
                        sign_up: "Registar",
                        or_login_with: "ou iniciar sessão com...",
                        or_register_with: "ou registar com...",
                        dont_have_account: "Não tenho uma conta.",
                        already_have_account: "Já tenho uma conta. Iniciar sessão",
                        terms_conditions: "Eu aceito os termos e condições.",
                        first_name: "Nome",
                        last_name: "Sobrenome",
                        example_email: "exemplo@swyco.io",
                        error_login: "Autenticação inválida. Email/password incorretos.",
                    },
                    my_account: {
                        hi: "Olá",
                        address_book: "Os meus endereços",
                        change_pwd: "Alterar senha",
                        my_activity: {
                            welcome: "Bem vindo",
                            my_activity: "A minha atividade",
                            no_activity: "De momento, não tens qualquer registo.",
                            start_activity: "Enviar as minhas peças"
                        },
                        my_address_book: {
                            address: "Endereço",
                            new_address: "Novo endereço de envio",
                            edit_address: "Editar endereço de envio",
                            first_name: "Primeiro Nome",
                            last_name: "Último Nome",
                            address1: "Morada",
                            address2: "Complemento de Morada",
                            calling_code: "Indicativo",
                            phone_number: "Telefone",
                            zip_code: "Código Postal",
                            city: "Cidade",
                            country: "País",
                            default_address: "Definir como meu endereço de entrega padrão.",
                            add_address: "Adicionar novo endereço",
                        },
                        change_password: {
                            password: "Senha atual",
                            new_password: "Nova senha",
                            confirm_password: "Confirmar nova senha"
                        }
                    },
                    ship_my_clothes: {
                        our_green_comunity: "Obrigado por fazeres parte da nossa",
                        green_comunity: "comunidade verde!",
                        reward: "Recompensamos os nossos membros com conteúdo exclusivo! Em que tipo de recompensa estás mais interessado?",
                        brands_suits_your_style: "Quais marcas de moda combinam com teu estilo?",
                        sell_donate_clothes: "Qual é o destino que dás às tuas roupas no final da sua vida útil?",
                        delivery_address: "Endereço de entrega",
                        choose_address: "Escolher endereço",
                        delivery_details: "Detalhes da entrega",
                        schedule: "Agendar",
                        pickup_point: "Ponto de envio",
                        pointers_near_me: "Encontrar pontos perto de mim",
                        change_location: "Alterar localização",
                        summary: "Sumário",
                        submit: "Submeter",
                        success_submission: "Obrigado pela submissão!",
                        receive_email_submission: "Receberás um e-mail com a sua",
                        receive_email_submission_cont: "etiqueta de envio.",
                        my_account: "A minha conta",
                        zipcode: "Código postal",
                        country: "País",
                        error_zipcode: "O código postal inserido é inválido."
                    },
                    legal_pages: {
                        how_it_works: "Como funciona?",
                        privacy_policy: "Política de privacidade",
                        terms_conditions: "Termos e condições"
                    }
                },
            }
        }
    });


export default i18n;