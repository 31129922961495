import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import Address from '../../components/Address';
import '../../App.css';

export default function NewAddress(props) {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const userLogin = localStorage.getItem("userLogin") ? JSON.parse(localStorage.getItem("userLogin")) : null
    const userData = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : null
    const [newAddress, setNewAddress] = useState({ FirstName: '', LastName: '', EmailAddress: '', callingCode: '+351', PhoneNumber: '', AddressLine1: '', AddressLine2: '', ZIPCode: '', City: '', CountryID: '', DefaultAddress: false })
    const [callingCode, setCallingCodes] = useState([])
    const [errorAddressInvalid, setErrorAddressInvalid] = useState(false)

    function addNewAddress() {
        setErrorAddressInvalid(false)
        if (newAddress.FirstName && newAddress.LastName && newAddress.AddressLine1 && newAddress.callingCode && newAddress.PhoneNumber && newAddress.PhoneNumber.length == 9 && newAddress.ZIPCode && newAddress.City && newAddress.CountryID) {
            newAddress.DefaultAddress = Number(newAddress.DefaultAddress)
            var phoneNumberAux = newAddress.PhoneNumber
            if (newAddress.PhoneNumber.split(' ').length > 1) {
                phoneNumberAux = newAddress.PhoneNumber.split(' ')[1]
            }
            newAddress.PhoneNumber = newAddress.callingCode + ' ' + phoneNumberAux
            userData?.address.push(newAddress)
            updateUser(userData)
        } else {
            setErrorAddressInvalid(true)
        }
    }
    async function updateUser(userData) {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + userLogin.Token);
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(userData)
        };

        await fetch(process.env.REACT_APP_URL + '/customers/storefront', requestOptions)
            .then(async response => {
                if (response.ok) {
                    return response.json();
                } else {
                    var error = await response.text();
                    return Promise.reject({ status: response.status, message: error });
                }
            })
            .then(response => {
                userData = response
                localStorage.setItem("userData", JSON.stringify(response))
                if (!props?.anotherpage) {
                    navigate("/my-account/my-address-book")
                } else {
                    var shipClothesAux = JSON.parse(localStorage.getItem("shipClothes"))
                    shipClothesAux.address_id = response.address[response.address.length - 1].AddressID
                    localStorage.setItem("shipClothes", JSON.stringify(shipClothesAux))
                    window.location.reload()
                }
            })
            .catch(error => {
                if(error.status == 401){
                    localStorage.clear()
                    navigate("/login-account")
                }
            })
    }
    function cancelAddAdress() {
        if (!props?.anotherpage) {
            navigate("/my-account/my-address-book")
        } else {
            var shipClothesAux = JSON.parse(localStorage.getItem("shipClothes"))
            shipClothesAux.address_id = null
            localStorage.setItem("shipClothes", JSON.stringify(shipClothesAux))
            window.location.reload()
        }
    }

    return (
        <div className='w-full h-full page-no-footer'>
            <div className='w-full h-full flex flex-col gap-3'>
                {!props?.anotherpage && <h2>{t("my_account.my_address_book.new_address")}</h2>}
                <div className='card w-full flex flex-col justify-between items-center gap-4'>
                    <Address errorAddressInvalid={errorAddressInvalid} address={newAddress} setAddress={setNewAddress} callingCode={callingCode} setCallingCodes={setCallingCodes}></Address>
                    <div className='w-full flex gap-2 justify-start'>
                        <button onClick={addNewAddress} className='w-auto button secundary-button'>{t("geral.save")}</button>
                        <button onClick={cancelAddAdress} className='w-auto button extra-button'>{t("geral.cancel")}</button>
                    </div>
                </div>
            </div>
        </div>
    );
}