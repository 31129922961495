
export default function TermsAndConditions() {
    return (
        <div className='w-full h-full page-footer-buttons'>
            <div className='w-full h-full flex flex-col gap-3'>
                <h2>Termos e Condições</h2>
                <div>
                    <section>
                        <ol class="mt-5 div-medium">
                            <li>
                                <div>
                                    <div>Estes Termos e Condições ("Termos") regem a sua utilização do serviço de recolha de roupas usadas fornecido pela Swyco ("Serviço"). 
                                    Ao aceder ou utilizar o Serviço, o utilizador concorda em ficar vinculado a estes Termos.</div>
                                </div>
                            </li>
                        </ol>
                    </section>
                    <section>
                        <ol start="1" class="mt-5 div-medium px-6 list-decimal">
                            <li>
                                <div>
                                    <div>Elegibilidade</div>
                                </div>
                            </li>
                        </ol>
                        <p class="mt-2">
                            <div>
                                <div>Para utilizar o Serviço, o utilizador deve ter pelo menos 18 anos de idade e ter a capacidade 
                                legal para celebrar um contrato. Ao utilizar o Serviço, o utilizador declara e garante que cumpre 
                                estes requisitos de elegibilidade.</div>
                            </div> 
                        </p>
                    </section>
                    <section>
                        <ol start="2" class="mt-5 div-medium px-6 list-decimal">
                            <li>
                                <div>
                                    <div>Processo de Recolha</div>
                                </div>
                            </li>
                        </ol>
                        <p class="mt-2">
                            <div>
                                <div>A SWYCO trata os seus dados pessoais durante o tempo necessário
                                    para a finalidade para a qual foram recolhidos.</div>
                            </div>
                        </p>
                        <p class="mt-2">
                            <div>
                                <div>Ao utilizar o Serviço, o utilizador concorda em seguir o processo de recolha conforme 
                                descrito pela Swyco. Isto inclui o acondicionamento adequado dos seus artigos de vestuário 
                                usados e o cumprimento de quaisquer instruções específicas fornecidas pela Swyco para a recolha.</div>
                            </div>
                        </p>
                        <ol class="mt-2 px-8">
                            <li>
                                <div>
                                    <div>Saiba mais aqui: 
                                        <a class="px-2 underline"aria-label="link swyco" href="/">Como funciona? </a>
                                    </div>
                                </div>
                            </li>
                        </ol>
                    </section>
                    <section>
                        <ol start="3" class="mt-5 div-medium px-6 list-decimal">
                            <li>
                                <div>
                                    <div>Propriedade do vestuário</div>
                                </div>
                            </li>
                        </ol>
                        <p class="mt-2">
                            <div>
                                <div>Ao utilizar o Serviço, confirma que é o legítimo proprietário dos artigos de vestuário
                                que submete para recolha. Concorda em não submeter quaisquer artigos roubados ou não 
                                autorizados para recolha.</div>
                            </div>
                        </p>
                    </section>
                    <section>
                        <ol start="4" class="mt-5 div-medium px-6 list-decimal">
                            <li>
                                <div>
                                    <div>Artigos aceites</div>
                                </div>
                            </li>
                        </ol>
                        <p class="mt-2">
                            <div>
                                <div>O serviço de recolha de retoma da Swyco foi especificamente concebido para aceitar 
                                artigos de vestuário usados. Apenas aceitamos artigos de vestuário, tais como tops, 
                                calças, vestidos, roupa exterior e acessórios (por exemplo, chapéus, cachecóis, luvas). 
                                Reservamo-nos o direito de recusar quaisquer artigos que não cumpram estes critérios ou 
                                que sejam considerados inadequados para recolha. Por favor, certifique-se de que apenas 
                                os artigos de vestuário são apresentados para reciclagem através da nossa plataforma.</div>
                            </div>
                        </p>
                    </section>
                    <section>
                        <ol start="5" class="mt-5 div-medium px-6 list-decimal">
                            <li>
                                <div>
                                    <div>Política de Privacidade</div>
                                </div>
                            </li>
                        </ol>
                        <p class="mt-2">
                            <div>
                                <div>A sua utilização do Serviço está sujeita à nossa Política de Privacidade, que rege a recolha, 
                                utilização e divulgação das suas informações pessoais. Ao utilizar o Serviço, o utilizador concorda 
                                com os termos da nossa Política de Privacidade.</div>
                            </div>
                        </p>
                    </section>
                    <section>
                        <ol start="6" class="mt-5 div-medium px-6 list-decimal">
                            <li>
                                <div>
                                    <div>Limitação de Responsabilidade</div>
                                </div>
                            </li>
                        </ol>
                        <p class="mt-2">
                            <div>
                                <div>A Swyco não será responsável por quaisquer danos, perdas ou despesas resultantes de ou relacionadas 
                                com a sua utilização do Serviço. Em nenhuma circunstância a Swyco será responsável por quaisquer danos 
                                indirectos, incidentais, especiais ou consequentes.</div>
                            </div>
                        </p>
                    </section>
                    <section>
                        <ol start="7" class="mt-5 div-medium px-6 list-decimal">
                            <li>
                                <div>
                                    <div>Modificações aos Termos</div>
                                </div>
                            </li>
                        </ol>
                        <p class="mt-2">
                            <div>
                                <div>A Swyco reserva-se o direito de modificar estes Termos em qualquer altura sem aviso prévio. 
                                    Quaisquer alterações a estes Termos entrarão em vigor imediatamente após a sua publicação. A 
                                    sua utilização continuada do Serviço após a publicação dos Termos revistos constitui a sua aceitação de tais alterações.</div>
                            </div>
                        </p>
                    </section>
                    <section>
                        <ol start="8" class="mt-5 div-medium px-6 list-decimal">
                            <li>
                                <div>
                                    <div>Lei aplicável</div>
                                </div>
                            </li>
                        </ol>
                        <p class="mt-2">
                            <div>
                                <div>Estes Termos serão regidos e interpretados de acordo com as leis de Portugal, sem 
                                ter em conta o seu conflito de disposições legais.</div>
                            </div>
                        </p>
                    </section>
                    <section>
                        <ol start="9" class="mt-5 div-medium px-6 list-decimal">
                            <li>
                                <div>
                                    <div>Contacte-nos</div>
                                </div>
                            </li>
                        </ol>
                        <p class="mt-2">
                            <div>
                                <div>Se tiver alguma questão sobre estes Termos, por favor contacte-nos através de: </div>
                                <div> <a class="tab text-primary" aria-label="e-mail swyco" href="mailto:info@swyco.io"> info@swyco.io </a> 
                                 ou Zona Industrial da Várzea, Rua do Parque Industrial, Lote 8 4755-539 Barcelos.</div>
                            </div>
                        </p>
                    </section>
                    <section>
                        <ol class="mt-5 div-medium">
                            <li>
                                <div>
                                    <div>Ao utilizar o Serviço, o utilizador reconhece que leu, compreendeu e concorda em ficar vinculado a estes Termos.</div>
                                </div>
                            </li>
                        </ol>
                    </section>
                </div>
            </div>
        </div>
    )
}